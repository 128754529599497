import { Header } from "../Header/Header";
import './About.scss';
import {Footer} from '../Footer/Footer';


export const About = function() {

    return (
        <>
            <Header/>
            <div className="aboutPage">
                <div className="container">
                    <h1> ПРО КОМПАНІЮ</h1>

                    <div className="aboutBox">
                        <div className="itemAboutBox">
                        Компанія заснована у 2006 році 
                        та вже встигла  себе зарекомендувати як команда професіоналів у галузі утеплення фасадів, стін та будинків у комплексі.
                        </div>
                        <div className="itemAboutBox">
                            <div className="imgAboutBox">
                            <img className="imgAboutPage" src="/img/aboutPage.png" alt="aboutUs" />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="advantagesPage">
                <div className="container">
                    <div className="advantagesBlock">
                    <div className="advantagesBox">
                        <img className="imgCompany" src="/img/logos.png" alt="company's logos" />
                    </div>
                    <div className="advantagesBox">
                        <h3>ОСНОВНІ ПЕРЕВАГИ:</h3>
                        <ul type="none">
                            <li>- понад 15 років на ринку;</li>
                            <li>- команда професіоналів;</li>
                            <li>- удосконалення та застосування передових технологій;</li>
                            <li>-використання високоякісних матеріалів провідних виробників;</li>
                            <li>
                            - своєчасне та якісне виконання робіт.
                            </li>
                        </ul>
                    </div>

                    </div>

                </div>

            </div>
            <Footer/>
        </>
    )
}
