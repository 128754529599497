export const images = [
    {
        id: 'id14657',
        src: '/img/portfolio/2.jpg',
        alt: 'house',
    },
    {
        id: 'id2754',
        src: './img/portfolio/7.jpg',
        alt: 'house',
    },
    {
        id: 'id354243',
        src: './img/portfolio/4.jpg',
        alt: 'house',
    },
    {
        id: 'id4lj52542',
        src: './img/portfolio/13.jpg',
        alt: 'house',
    },
    {
        id: '5',
        src: './img/portfolio/33.jpg',
        alt: 'house',
    },
    {
        id: '6',
        src: './img/portfolio/8.jpg',
        alt: 'house',
    }
]