import React from "react";
import { useEffect, useState } from "react";

import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";
import { Cart } from "../Cart/Cart";
import { NavBar } from "../NavBar/NavBar";
import Pagination from "../Pagination";

// import "../Plaster/Plaster";

export const Waterproofing = ({ openProduct }) => {
  /**
   * param {array} data
   */
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [catalogPerPage] = useState(9);
  const lastDataIndex = currentPage * catalogPerPage;
  const firstDataIndex = lastDataIndex - catalogPerPage;
    
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      fetch("/db/db.json")
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setIsLoading(false);
        })

        .catch((err) => console.log(err));
    }, 2000);
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <h2>Loading...</h2>
        <img src="/img/loadingLogo.png" alt="logo" width={100} height={100} />
      </div>
    );
  }


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0,0);

  };

  const arrCurrent = data.filter((item) => item.category === "waterproofing");

  const currentProducts = arrCurrent.slice(firstDataIndex, lastDataIndex);

  return (
    <>
      <Header />

      <div className="container">
          <h2 style={{textAlign: 'center'}}>Гідроізоляція</h2>
        <div className="catalog-boxP">
          <NavBar />
          <div className="productsBoxP">
            {currentProducts.map((item) => (
              <div
                className="cartBoxP"
                style={{ cursor: "pointer" }}
                onClick={() => openProduct(item.id)}
                key={item.id}
              >
                <Cart
                  name={item.name}
                  packege={item.packege}
                  price={item.price}
                  image={item.image}
                  id={item.id}
                />
              </div>
            ))}
          </div>
        </div>
        <Pagination
          catalogPerPage={catalogPerPage}
          totalProducts={arrCurrent.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
      <Footer />
    </>
  );
};
