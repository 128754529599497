import { Header } from "../Header/Header";
import "./Servises.scss";
import { Footer } from "../Footer/Footer";
import { useState } from "react";
import { Accordion } from "./Accordion";

export const Services = function () {
  const [active, setActive] = useState('');

  const dataAccordion = [
    {
      title: "Інтер'єр",
      name: 'interior',
      content: [
        { 
          headList: " Ми надаємо послуги по ремонту інтер'єра будь-якої складності:",
          li: [
            "ремонт квартир під ключ;",
            "ремонт кухні, ванної, туалету;",
            "укладання плитки;",
            "монтаж гіпсокартону;",
            "штукатурка стін;",
            "шпаклівка стін;",
            "малярні роботи;",
            "безповітряне фарбування;",
            "натяжні стелі;",
            "зварювальні роботи;",
            "демонтажні роботи;",
            "монтаж вагонки;",
            "встановлення міжкімнатних та вхідних дверей;",
            "встановлення вікон;",
            "гідрофзоляціоннф роботи;",
            "кондиціюання;",
            "декоративна штукатурка;",
            "встановлення замків и сейфів;",
            "надаємо послуги електрика (електромонтажні роботи, встановлення автомата, підключення ОЗП, монтаж освітлення, встановлення розеток, люстр, світлодіодних стрічок та ін.);",
            "надаємо послуги сантехніка (установка ванн, унітазів, біде, змішувачів, душових кабін, сушки для рушників та ін.);",
            "монтаж труб;",
            "заміна труб у квартирі",
            "монтаж опалення;",
            "монтаж систем вентиляції;",
          ],
        },
      ],
    },
    {
      title: "Ексте'єр",
      name: 'exterior',
      content: [
        {
          headList: "Ми використовуємо тільки сучасні технології утеплення, які дозволяють змістити точку роси за межі стін будівлі, а також виконати роботи не торкаючись внутрішнього ремонту. Ми використовуємо у своїй роботі такі методи утеплення:",
          li: [
            "Мокрий фасад;",
            "Вентелюваний фасад;",
            "Тришаровий фасад;",
            "Фасадні панелі;",
            "Фасадний керамограніт;",
            "Панелі під цеглу;",
            "Фіброцементні плити;", 
            "Панелі під дерево;",
            "HPL-панелі;",
            "SKANROC;"
          ],
        },
        {
          headList: "Виконуємо всі види гідроізоляції:",
          li: [
            "гідроізоляція фундаментів;",
            "гідроізоляція перекриттів, покрівлі;",
            "гідроізоляція підвалів та цокольних поверхів;",
            "гідроізоляція тех.підпілля, підлоги, стін;",
            "гідроізоляція тунельних споруд, водоводів, підпірних стінок, трубних проходок;",
            "гідроізоляція басейнів, чаш, резервуарів для зберігання рідин;",
            "гідроізоляція деформаційних швів", 
            
          ],
        },
      ],
    },
    {
      title: "Послуги дизайнера та архітектора",
      name: 'designer',
      content: [
        {
          headList: " Наша компанія надає послуги дизайнера:",
          li: [
            "виїзд на об'єкт, обміри, фотофіксація;",
            "визначення стилю та концепції проекту",
            "розробка варіантів планувального рішення з розміщенням меблів;",
            "3D-візуалізація;",
            " виконання повного комплекту робочих креслень",
            "комплектація об'єкта із асортименту магазинів партнерів;",
            
          ],
        },
        {
          headList: " А також послуги архітектора:",
          li: [
            "Обмірювальне креслення ділянки;",
            "Схема прив'язки будови до ділянки;",
            "Схема ростверку, фундаменту;",
            "Поверхові плани;",
            "Схема покрівлі;",
            "Вентиляційні стояки;",
            "Фасади;",
            "Необхідні розрізи;",
            "3D-візуализація;",
            "Креслення архітектурних вузлів та деталей;",
            "Схема маркування дверних та віконних отворів;",
            
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Header />
      <div className="servicePageTop">
        <div className="container">
          <h1 className="services-title">Наші послуги</h1>
          <div className="servicePageBox">
            {
              dataAccordion.map((item, index)=> (
                <Accordion 
                title={item.title} 
                name={item.name} 
                content={item.content} 
                key={index}
                active={active}
                setActive={setActive}
                />
              ))
            }
          </div>
        </div>
      </div>
      <div className="blockWhy">
        <div className="container">
          <div className="whyWe-box">
            <div className="benefitUs">
              <h3>ЧОМУ САМЕ МИ?</h3>
              <p>
                Наша компанія виконує утеплення фасадів та стін за допомогою
                «мокрого» методу.
              </p>
              <ul type="none">
              Переваги системи "мокрий фасад":
                 <li>- високий рівень теплоізоляції стін;</li>
                 <li>- надійна звукоізоляція стін;</li>
                 <li>
                   - Застосування легких огороджувальних конструкцій без втрати
                   теплостійкості;
                 </li>
                 <li>
                   - Збільшення корисної площі будівлі за рахунок зменшення
                   товщина стін;
                 </li>
                 <li>- відсутність «висолів» на фасадах;</li>
                 <li>
                   - «мокрий фасад» можна монтувати на різних поверхнях
                   видів;
                 </li>
                 <li>
                   - «Мокрі» фасади монтуються на ослаблені поверхні:
                   стару цегляну кладку, стіни "хрущовок" і т.д.
                </li>
              </ul>
            </div>
            <div className="benefitImg">
              <img src="/img/benefit.png" alt="our benefit" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
