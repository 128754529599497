import React from 'react'

function Pagination({catalogPerPage, totalProducts, paginate, currentPage}) {
    const pageNumber =[];
    for(let i = 1; i <= Math.ceil(totalProducts / catalogPerPage); i++) {
        pageNumber.push(i);
    }

    return (
        <div>
           <ul className="pagination" type="none" style={{textAlign:'center'}}>
                {
                    pageNumber.map(number => (
                        <li className="page-item" key={number} style={{display:'inline', margin:'0 5px'}}>
                            <button className="page-link" 
                            style={{textDecoration:'none', color:'black', fontWeight:'600', backgroundColor: currentPage === number? '#c2d0b2': ''}}
                            onClick={()=>paginate(number)}>
                                {number}
                            </button>
                            </li>
                    ))
                }
            </ul> 
        </div>
    )
}

export default Pagination
