import { Link } from "react-router-dom";
import "./Menu.scss";

export const Menu = function () {
  function open() {
    document.getElementById('menu').classList.toggle("show");
    document.getElementById('trigger').classList.toggle("show");
    
    
  }

  return (
    <div className="menuBlock">
        <span id="trigger" className="trigger" onClick={open}>
          <span></span>
        </span>

      <div className="nav" id="nav">
        <ul id="menu" className="menu">
          <li>
            <Link to="/home">Головна</Link>
          </li>
          <li>
            <Link to="/catalog">Каталог товарів</Link>
          </li>
          <li>
            <Link to="/services">Послуги</Link>
          </li>
          <li>
            <Link to="/portfolio">Портфоліо</Link>
          </li>
          <li>
            <Link to="/about">Про Компанію</Link>
          </li>
          <li>
            <Link to="/contacts">Контакти</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
