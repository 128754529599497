import { v4 as uuid } from 'uuid';


export const photos = [
    {
        id : uuid(),
        src : '/img/portfolio/1.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/2.jpg',
        alt : 'photo our work',  
    },
    {
        id : uuid(),
        src : '/img/portfolio/3.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/4.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/5.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/6.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/7.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/8.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/9.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/10.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/11.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/12.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/13.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/14.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/15.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/16.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/17.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/18.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/19.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/20.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/21.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/22.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/23.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/24.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/25.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/26.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/27.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/28.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/29.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/30.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/31.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/32.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/33.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/34.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/35.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/36.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/37.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/38.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/39.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/40.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/41.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/42.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/43.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/44.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/45.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/46.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/47.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/48.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/49.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/50.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/51.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/52.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/53.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/54.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/55.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/56.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/57.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/58.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/59.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/60.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/61.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/62.jpg',
        alt : 'photo our work',
    },
    {
        id : uuid(),
        src : '/img/portfolio/63.jpg',
        alt : 'photo our work',
    },
   
]


    