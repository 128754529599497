import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import "./Contacts.scss";


export const Contacts = function () {
  return (
    <>
      <Header />
      <main className="contactsPage">
        <div className="container">
          <h1>Контакти</h1>
          <div className="contactsBlock">
            <div className="contactInfo">
              <ul type="none">
                <li>
                  <i className="fas fa-map-marked-alt"></i>
                 м. Харків, вул. Отакаро Яроша, 18
                </li>
                <li>
                  <i className="fas fa-map-marked-alt"></i>
                 м. Київ, вул. Мечникова, 14
                </li>
                <li>
                  <a href={"mailto:warmdom600@gmail.com"}>
                    <i className="fas fa-envelope"></i>
                    warmdom600@gmail.com
                  </a>
                </li>
                <li>
                  <a href={"tel:+380665714156"}>
                    <i className="fas fa-phone-alt"></i>
                    +38 (066) 57 141 56
                  </a>
                </li>
                <li>
                  <div className="social-icon">
                    <div className="icon-insta">
                      <a href={"https://www.instagram.com/warm_dom_kh/"}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                    <div className="icon-fb">
                      <a
                        href={
                          "https://www.facebook.com/profile.php?id=100034690310497"
                        }
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div className="icon-mail">
                      <a href={"mailto:warmdom600@gmail.com"}>
                        <i className="far fa-envelope"></i>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.1859669092137!2d36.21688261506144!3d50.02661107941914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a1365d886413%3A0x5352441f03b7f0fa!2z0YPQuy4g0J7RgtCw0LrQsNGA0LAg0K_RgNC-0YjQsCwgMTgsINCl0LDRgNGM0LrQvtCyLCDQpdCw0YDRjNC60L7QstGB0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCA2MTAwMA!5e0!3m2!1sru!2sua!4v1633943036490!5m2!1sru!2sua"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                title="our adress"
              ></iframe>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
