import React, { useEffect, useState } from "react";

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Cart } from "./Cart/Cart";
import "./Catalog.scss";
import { NavBar } from "./NavBar/NavBar";
import Pagination from "./Pagination";

export const Catalog = function ({ openProduct }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [catalogPerPage] = useState(9);
  const lastDataIndex = currentPage * catalogPerPage;
  const firstDataIndex = lastDataIndex - catalogPerPage;
  const currentProducts = data.slice(firstDataIndex, lastDataIndex);
  const [value, setValue] = useState("");

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      fetch("./db/db.json")
        .then((res) => res.json())
        .then((data) => {
          debugger;
          setData(data);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }, 2000);
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <h2>Loading...</h2>
        <img src="/img/loadingLogo.png" alt="logo" width={100} height={100} />
      </div>
    );
  }
  const filtered = data.filter((item) => {
    return item.name.toLowerCase().includes(value.toLowerCase());
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0,0);

  };
    


  return (
    <>
      <Header />
      <div className="container">
        <div className="pageCatalog-box">
          <div className="navigation">
            <NavBar />
            <div className="searchBox">
              <form className="searchForm">
                <input
                  type="text"
                  placeholder="Поиск товара..."
                  className="searchInput"
                  onChange={(event) => setValue(event.target.value)}
                  
                />
              </form>
            </div>
          </div>
          <div className="productsBox">
            {value === ""
              ? currentProducts.map((item, index) => (
                  <div
                    className="cartBox"
                    onClick={() => openProduct(item.id)}
                    key={item.id}
                  >
                    <Cart
                      name={item.name}
                      packege={item.packege}
                      price={item.price}
                      image={item.image}
                      id={item.id}
                    />
                  </div>
                ))
              : filtered.map((item, index) => (
                  <div
                    className="cartBox"
                    onClick={() => openProduct(item.id)}
                    key={item.id}
                  >
                    <Cart
                      name={item.name}
                      packege={item.packege}
                      price={item.price}
                      image={item.image}
                      id={item.id}
                    />
                  </div>
                ))}
          </div>
        </div>
        <Pagination
          catalogPerPage={catalogPerPage}
          totalProducts={data.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
      <Footer />
    </>
  );
};
