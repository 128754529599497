import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.scss";

export const NavBar = ({setValue}) => {
  

 
  function openNav() {
    document.getElementById("navBar").classList.toggle("noneBar");
    document.getElementById("arrow").classList.toggle("closeNav");
  }


  return (
    <div className="navBox">
      <div className="navTitleBox">
        <Link to="/catalog">
          <h2>Каталог товаров</h2>
        </Link>
        <div className="arrowNav" onClick={openNav}>
          <i className="fas fa-sort-up" id="arrow"></i>
        </div>
      </div>
      <div className="navBar" id="navBar">
        <ul className="navList" type="disc">
          <li className="navItem">
            <Link to={`/catalog/plaster`}>Декоративна штукатурка</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/material`}>Декоративні матеріали</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/glue`}>Клей</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/priming`}>Грунт</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/waterproofing`}>Гідроізоляція</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/facadedye`}>Фасадна фарба</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/intodye`}>Фарба для внутрішніх робіт</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/addition`}>Додаткові матеріали</Link>
          </li>
          <li className="navItem">
            <Link to={`/catalog/monting`}>Виносний монтаж</Link>
          </li>
        </ul>
      </div>
      
    </div>
  );
};
