import { MainHeader } from "../Header/MainHeader";
import './HomePage.scss';
import Sliders from "./Slider/Slider";
import {Footer} from '../Footer/Footer';
import { Link } from "react-router-dom";


export const HomePage = function (props) {
    


    return (
        <>
        <MainHeader/>
        <main>
            <div className="advantages-box">
                <div className="container">
                    <section className="advantages">
                        <h2 className="title" >НАШІ ПЕРЕВАГИ</h2>
                        <div className="facts">
                            <div className="fact">
                                <div className="num">10+</div>
                                <div className="text">років на ринку</div> 
                            </div>
                            <div className="fact">
                            <div className="num">200+</div>
                                <div className="text">задоволених клієнтів</div>
                            </div>
                            <div className="fact">
                            <div className="num">105000+</div>
                                <div className="text">утеплено квадратних метрів</div> 
                            </div>
                        </div>
                    </section>
                </div>
            </div>
             <section>
                <div className="container">
                    <div className="catalog">
                        <Link to="/catalog" style={{color: 'black', textDecoration: 'none', cursor: 'pointer'}}><h2 className="catalogTitle">КАТАЛОГ ПРОДУКЦИИ</h2></Link>
                        <div className="catalog-box">
                            <div className="item item-plaster"><Link to="/catalog/plaster">Декоративна штукатурка</Link></div>
                            <div className="item item-material"><Link to="/catalog/material">Декоративні матеріали</Link></div>
                            <div className="item item-glue"><Link to="/catalog/glue">Клей</Link></div>
                            <div className="item item-priming"><Link to="/catalog/priming">Грунт</Link></div>
                            <div className="item item-waterproofing"><Link to="/catalog/waterproofing">Гідроізоляція</Link></div>
                            <div className="item item-facadeDye"><Link to="/catalog/facadedye">Фасадна фарба</Link></div>
                            <div className="item item-intoDye"><Link to="/catalog/intodye">Фарба для внутрішніх робіт</Link></div>
                            <div className="item item-addition"><Link to="/catalog/addition">Додаткові матеріали</Link></div>
                            <div className="item item-monting"><Link to="/catalog/monting">Виносний монтаж</Link></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-box">
                <div className="container">
                    <div className="about">
                        <div className="about-item border">
                            <div className="about-imgA"></div>
                        </div>
                        <div className="about-item">
                            <h2>ПРО НАС</h2>
                            <p>
                            З 2006 року наша компанія успішно працює та развиваєтбся на ринку утеплення фасадів, стін та будинків у комплексі.
                            </p>
                            <Link to="/about" className="btnAbout">Більше про компанію WarmDom</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="slider-box">
                    <Sliders/>
            </section>
        </main>
        <Footer/>
        </>
    )
}
