import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { photos } from "./photos";
import "./Portfolio.scss";
import React, { useState } from "react";
import { ModalImg } from "./ModalImg/ModalImg";

export const Portfolio = function () {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.src);
  };

  const handelRotationRight = () => {
    const totalLength = photos.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = photos[0].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = photos.filter((item) => {
      return photos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = photos.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = photos[totalLength - 1].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = photos.filter((item) => {
      return photos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    <>
      <Header />
      <main className="photo-box">
        <div className="container">
          <h1>Портфоліо</h1>
          <div className="portfolio">
            {photos.map((item, index) => (
              <div key={index} className="itemPhoto">
                <img
                  className="imgGalery"
                  src={item.src}
                  alt={item.alt}
                  onClick={() => handleClick(item, index)}
                />
              </div>
            ))}
            <div>
              {clickedImg && (
                <ModalImg
                  clickedImg={clickedImg}
                  handelRotationRight={handelRotationRight}
                  setClickedImg={setClickedImg}
                  handelRotationLeft={handelRotationLeft}
                />
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
