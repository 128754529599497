import React, { useEffect, useState } from "react";
import "./Modal.scss";

export const Modal = ({ active, setActive }) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [numberDirty, setNumberDirty] = useState(false);
  const [nameError, setNameError] = useState("Поле не может быть пустым");
  const [numberError, setNumberError] = useState("Поле не может быть пустым");
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (nameError || numberError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [nameError, numberError]);

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "number":
        setNumberDirty(true);
        break;
      case "name":
        setNameDirty(true);
        break;
      default:
    }
  };

  const nameHandler = (e) => {
    setName(e.target.value);
    const re = /^[А-ЯЁа-яёa-zA-Z]+$/g;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setNameError("Ім'я не коректно");
    } else {
      setNameError("");
    }
  };
  const numberHandler = (e) => {
    setNumber(e.target.value);
    const re = /^[0-9]+$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setNumberError("Номер не корректен");
    } else {
      setNumberError("");
    }
    if (e.target.value.length !== 10) {
      setNumberError("Номер не корректен");
    } else {
      setNumberError("");
    }
  };

  return (
    <div
      className={active ? "modal active" : "modal"}
      onClick={() => setActive(false)}
    >
      <div
        className={active ? "modal-content active" : "modal-content"}
        onClick={(e) => e.stopPropagation()}
      >
        <section>
          <form
            action="https://formsubmit.co/warmdom600@gmail.com"
            method="POST"
            id="my-form"
          >
            <div className="form-group">
              <label htmlFor="firstName"> Ім'я</label>
              {nameDirty && nameError && (
                <div style={{ color: "red" }}>{nameError}</div>
              )}
              <input
                onChange={(e) => nameHandler(e)}
                onBlur={(e) => blurHandler(e)}
                value={name}
                type="text"
                id="firstName"
                name="name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="number">Номер телефона</label>
              {numberDirty && numberError && (
                <div style={{ color: "red" }}>{numberError}</div>
              )}
              <input
                onChange={(e) => numberHandler(e)}
                onBlur={(e) => blurHandler(e)}
                value={number}
                type="tel"
                id="number"
                name="number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" />
            </div>

            <div className="form-group">
              <label htmlFor="massage">Текст</label>
              <textarea
                name="massage"
                id="massage"
                cols="30"
                rows="5"
              ></textarea>
            </div>
            <input
              type="hidden"
              name="_ next"
              value="https://localhost:3000/thankfulpage"
            />
            
            <button disabled={!formValid} type="submit">
              Відправити
            </button>
          </form>
          <div id="status"></div>
        </section>
      </div>
    </div>
  );
};
