import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { images } from "./image";
import './Slider.scss';


export default function Sliders() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              dots: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
          }
        ]
      };
  return (
    <div className="container">
        <Link to="/portfolio" style={{textAlign:'center', 
        fontSize:'40px', 
        textDecoration: 'none', 
        color: 'black', 
        fontWeight:'700', 
        display: 'block',
        margin:'0 auto', padding: '20px 0'}}>Портфоліо</Link>
        <Slider {...settings}>
            {images.map((item) =>(
                <div className="slickSliderBox" key={item.id}>
                    <img src={item.src}  alt={item.alt} style={{width:'300px', height:'200px', boxShadow: '0 0 5px grey'}}></img>
                </div>
            ))}
        </Slider>
    </div>
  );
}
