import logo from "./logo.png";
import "./Footer.scss";
import { Link } from "react-router-dom";

export const Footer = function () {
  return (
    <footer>
      <div className="footerBox">
        <div className="item">
          <img src={logo} alt="logo" width="250px" height="200px"></img>
        </div>
        <div className="item">
          <p>
            <Link to="/catalog">КАТАЛОГ ПРОДУКЦІЇ</Link>
          </p>
          <ul type="disc">
            <li>
              <Link to="/catalog/plaster">Декоративна штукатурка</Link>
            </li>
            <li>
              <Link to="/catalog/material">ДекоративнІ матеріали</Link>
            </li>
            <li>
              <Link to="/catalog/glue">Клей</Link>
            </li>
            <li>
              <Link to="/catalog/priming">Грунт</Link>
            </li>
            <li>
              <Link to="/catalog/waterproofing">Гідроізоляція</Link>
            </li>
            <li>
              <Link to="/catalog/facadedye">Фасадна фарба</Link>
            </li>
            <li>
              <Link to="/catalog/intodye">Фарба для внутрішніх робіт</Link>
            </li>
            <li>
              <Link to="/catalog/addition">Додаткові матеріали</Link>
            </li>
            <li>
              <Link to="/catalog/monting">Виносний монтаж</Link>
            </li>
          </ul>
        </div>
        <div className="item">
          <p>
            <Link to="/services">ПОСЛУГИ</Link>
          </p>
          <p>
            <Link to="/portfolio">ПОРТФОЛІО</Link>
          </p>
          <p>
            <Link to="/about">ПРО КОМПАНІЮ</Link>
          </p>
        </div>
        <div className="item">
          <p>
            <Link to="/contacts">КОНТАКТИ</Link>
          </p>
          <ul>
            <li>Киів, вул. Мечникова 14</li>
            <li>Харків, вул. Отакаро Яроша 18</li>
            <li>
              <a href={'tel:+380665714156'}>+38 (066) 57 141 56</a>
            </li>
            <li>
              <a href={'mailto:warmdom600@gmail.com'}>
                warmdom600@gmail.com
              </a>
            </li>
          </ul>
          <div className="social-icon">
            <div className="icon-insta">
              <a href={"https://www.instagram.com/warm_dom_kh/"}>
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="icon-fb">
              <a href={"https://www.facebook.com/profile.php?id=100034690310497"}
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
            <div className="icon-mail">
              <a href={"mailto:warmdom600@gmail.com"}>
                <i className="far fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="copyright">
        Авторське право © 2021 warmdom.com.ua Усі права захищені.
      </div>
    </footer>
  );
};
