import React from 'react';
import './Servises.scss';

export const Accordion = ({title, name, content, active, setActive}) => {

    return (
        
            <div className="typeOfService">
                <div className={`typeOfService-item  ${name} `}
                onClick={()=>setActive(title)}
                >{title}</div>
                <div className={`${(active === title ? 'typeOfService-item-content' : 'typeOfService-item-hidden')} ` }>
                    <div className={`${(active === title ? 'content-overflow' : 'content-hidden')} `}>
                        {content.map((cont, index) =>(
                            <ul style={{display: active=== title ? 'block' : 'none'}} key={index}>
                                {cont.headList}
                                {cont.li.map((text, index)=>(
                                    <li key={index}>{text}</li>
                                ))}
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
       
    )
}
