import "./App.scss";
import { HomePage } from "./component/HomePage/HomePage";

// import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Catalog } from "./component/Catalog/Catalog";
import { Services } from "./component/Services/Services";
import { Portfolio } from "./component/Portfolio/Portfolio.jsx";
import { About } from "./component/About/About";
import { Contacts } from "./component/Contacts/Contacts";
import { Plaster } from "./component/Catalog/Plaster/Plaster";
import { Addition } from "./component/Catalog/Addition/Addition";
import { FacadeDye } from "./component/Catalog/FacadeDye/FacadeDye";
import { Glue } from "./component/Catalog/Glue/Glue";
import { IntoDye } from "./component/Catalog/IntoDye/IntoDye";
import { Material } from "./component/Catalog/Material/Material";
import { Monting } from "./component/Catalog/Monting/Monting";
import { Priming } from "./component/Catalog/Priming/Priming";
import { Waterproofing } from "./component/Catalog/Waterproofing/Waterproofing";
import { Product } from "./component/Catalog/Product/Product";


function App() {
  const history = useHistory();

  function openPageProduct(newPage) {
    history.push(`/catalog/product/${newPage}`);
  }

  return (
    <div>
      <Switch>
        <Route path="/home">
          <HomePage />
        </Route>
        <Route
          path="/catalog"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} exact>
                <Catalog openProduct={openPageProduct} />
              </Route>
              <Route path={`${url}/plaster`}>
                <Plaster openProduct={openPageProduct} />
              </Route>
              <Route path={`${url}/addition`}>
                <Addition openProduct={openPageProduct} />
              </Route>
              <Route path={`${url}/material`}>
                <Material openProduct={openPageProduct}/>
              </Route>
              <Route path={`${url}/glue`}>
                <Glue openProduct={openPageProduct}/>
              </Route>
              <Route path={`${url}/priming`} >
                <Priming openProduct={openPageProduct}/>
                </Route>
              <Route path={`${url}/waterproofing`}>
                <Waterproofing openProduct={openPageProduct}/>
                </Route>
              <Route path={`${url}/facadedye`}>
                <FacadeDye openProduct={openPageProduct}/>
                </Route>
              <Route path={`${url}/intodye`}>
                <IntoDye openProduct={openPageProduct}/>
                </Route>
              <Route path={`${url}/monting`}>
                <Monting openProduct={openPageProduct}/>
                </Route>
              <Route path={`${url}/product/:id`}>
                <Product />
              </Route>
            </>
          )}
        />
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contacts">
          <Contacts />
        </Route>
        <Route path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/product">
          <Product openProduct={openPageProduct} />
        </Route>
        <Route path="/catalog/product/:id">
          <Product openProduct={openPageProduct} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
