import React from "react";
import './Cart.scss';

export const Cart = ({ name, packege, price, image, id }) => {

  return (
    <>
      {
        <div className="wrapperCart" key={id} style={{cursor:'pointer'}}>
          <img className="cartImg" src={image} alt="product" ></img>
          <p>{name}</p>
          <p>{packege}</p>
          <div className="wrapPrice">
            {price.map((item, i) => (
              
                <div className="row" key={i}>
                  <div className="column" >{item.key}</div>
                  <div className="column" style={{fontWeight: '600'}}>{item.value}</div>
                </div>
              
            ))}
          </div>
        </div>
      }
    </>
  );
};
