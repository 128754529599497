import { Menu } from "../Menu/Menu";
import './Header.scss';
import { Link } from "react-router-dom";

export const Header = function () {

    return (
      <header className="header">
        <div className="container">
          <div className="header-block">
          <Link to="/home">
            <div className="logo"> 
            <svg className="logosvg" xmlnsXlink="http://www.w3.org/1999/xlink" 
        xmlns="http://www.w3.org/2000/svg" version="1.1" id="Logotype" x="0px" y="0px" 
        width="400px" height="200px" viewBox="0 0 4000 3326"><rect x="0" y="0" width="4000" height="3326" 
        fill="none" id="background"/><defs><font id="Akrobat-Bold" horizAdvX="453"><font-face fontFamily="Akrobat Bold" unitsPerEm="1000" panose1="0 0 8 0 0 0 0 0 0 0" ascent="1000" descent="-200" alphabetic="0"/>
<missing-glyph horizAdvX="500" d="M410 800V-200H93V800H410ZM333 733H168V700H233V663H167V630H333V663H267V700H333V733ZM267 604H167V500H333V534H267V604ZM233 570V534H200V570H233ZM333 473H167V440H233V403H167V370H267V440H333V473ZM333 413H300V347H167V313H333V413ZM333 291H233V235H267V258H300V211H200V291H167V178H333V291ZM333 118H167V5H333V118ZM300 85V38H200V85H300ZM333 -18H167V-51H237L167 -98V-131H333V-98H231L301 -51H333V-18Z"/>
<glyph unicode=" " glyphtame="space" horizAdvX="215"/>
<glyph unicode="!" glyphtame="exclam" horizAdvX="249" d="M76 167V700H173V167H76ZM125 -11Q97 -11 81 5T64 50Q64 77 80 93T125 110Q152 110 168 94T185 50Q185 22 169 6T125 -11Z"/>
<glyph unicode="&quot;" glyphtame="quotedbl" horizAdvX="282" d="M40 700H136L79 465H19L40 700ZM169 700H265L209 465H149L169 700Z"/>
<glyph unicode="#" glyphtame="numbersign" horizAdvX="617" d="M88 0L129 179H10L27 253H145L187 436H69L85 510H204L248 700H336L292 510H399L442 700H531L488 510H607L590 436H471L429 253H548L531 179H413L371 0H282L323 179H217L176 0H88ZM233 253H340L382 436H275L233 253Z"/>
<glyph unicode="$" glyphtame="dollar" horizAdvX="465" d="M435 180Q435 108 391 55T269 -10V-85H187V-10Q112 3 70 56T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 603 78 650T187 709V785H269V710Q341 699 382 648T425 517H322Q321 566 297 591T231 616T165 594T141 534Q141 497 171 467T245 412T331 360T404 286T435 180Z"/>
<glyph unicode="%" glyphtame="percent" horizAdvX="583" d="M112 390Q65 390 37 418T9 499V600Q9 652 37 681T112 710Q157 710 185 681T213 600V499Q213 447 186 419T112 390ZM20 0L489 700H562L94 0H20ZM112 448Q149 448 149 498V601Q149 652 112 652Q73 652 73 601V498Q73 448 112 448ZM464 -9Q417 -9 389 19T361 100V202Q361 254 389 283T464 312Q509 312 537 283T565 202V100Q565 48 538 20T464 -9ZM464 50Q501 50 501 99V203Q501 253 464 253Q426 253 426 203V99Q426 50 464 50Z"/>
<glyph unicode="&amp;" glyphtame="ampersand" horizAdvX="588" d="M553 0H449Q441 13 425 36T406 64Q332 -13 242 -13Q170 -13 120 33T70 161Q70 225 100 275T184 377Q112 492 112 561Q112 624 151 668T263 713Q338 713 376 668T413 558Q412 502 383 457T301 362L402 219Q420 256 428 287H524Q501 204 460 136Q509 68 553 0ZM202 558Q202 517 250 438Q322 506 322 557Q322 586 307 606T263 627T219 609T202 558ZM248 78Q301 78 352 139Q332 167 293 220T236 300Q167 229 167 169Q167 129 190 104T248 78Z"/>
<glyph unicode="'" glyphtame="quotesingle" horizAdvX="152" d="M40 700H136L79 465H19L40 700Z"/>
<glyph unicode="(" glyphtame="parenleft" horizAdvX="292" d="M246 -122Q164 -120 117 -70T70 65V633Q70 718 117 768T246 820V729Q208 727 188 704T167 638V61Q167 -26 246 -31V-122Z"/>
<glyph unicode=")" glyphtame="parenright" horizAdvX="292" d="M46 -122V-31Q125 -26 125 61V638Q125 680 105 703T46 729V820Q128 818 175 768T222 633V65Q222 -20 175 -70T46 -122Z"/>
<glyph unicode="*" glyphtame="asterisk" horizAdvX="306" d="M105 484L52 522L100 589L23 614L42 676L120 650V732H185V650L263 675L283 614L205 589L253 522L200 484L152 550L105 484Z"/>
<glyph unicode="+" glyphtame="plus" horizAdvX="461" d="M417 355V265H275V119H185V265H43V355H185V501H275V355H417Z"/>
<glyph unicode="," glyphtame="comma" horizAdvX="169" d="M47 98H143L86 -136H26L47 98Z"/>
<glyph unicode="-" glyphtame="hyphen" horizAdvX="321" d="M38 214V300H283V214H38Z"/>
<glyph unicode="." glyphtame="period" horizAdvX="194" d="M124 -11T97 -11T53 5T36 50Q36 77 53 93T97 110T141 94T158 50Q158 22 141 6Z"/>
<glyph unicode="/" glyphtame="slash" horizAdvX="424" d="M330 813H416L94 -119H7L330 813Z"/>
<glyph unicode="0" glyphtame="zero" horizAdvX="471" d="M235 -10Q150 -10 100 42T50 186V512Q50 603 100 656T235 710Q321 710 371 657T421 512V186Q421 95 371 43T235 -10ZM191 81T235 81T303 108T327 186V512Q327 564 303 591T235 619T168 592T144 512V186Q144 136 167 109Z"/>
<glyph unicode="1" glyphtame="one" horizAdvX="297" d="M155 700H227V3H130V569L25 495V605L155 700Z"/>
<glyph unicode="2" glyphtame="two" horizAdvX="421" d="M28 0V67Q28 131 55 189T120 291T196 374T261 456T288 538Q288 573 267 596T208 620Q171 620 149 595T126 523H33Q37 612 83 661T207 711Q290 711 337 661T384 541Q384 489 358 438T294 345T219 263T155 178T128 90H389V0H28Z"/>
<glyph unicode="3" glyphtame="three" horizAdvX="421" d="M229 422Q302 418 341 369T380 243V197Q380 95 334 42T198 -11Q114 -11 66 40T15 189H111Q116 83 196 83Q241 83 262 110T283 198V241Q283 297 259 321T186 345H122V413L245 612H39V701H363V635L229 422Z"/>
<glyph unicode="4" glyphtame="four" horizAdvX="432" d="M430 263V174H352V0H257V174H9V226L154 700H251L116 263H258L268 509H352V263H430Z"/>
<glyph unicode="5" glyphtame="five" horizAdvX="429" d="M238 475Q310 475 353 423T396 276V205Q396 99 351 44T223 -11Q143 -11 97 39T47 178H145Q149 83 223 83Q261 83 279 110T298 204V277Q298 333 281 358T225 383Q163 383 148 316H53V700H374V613H150V447Q188 475 238 475Z"/>
<glyph unicode="6" glyphtame="six" horizAdvX="445" d="M243 459Q313 459 360 410T408 284V173Q408 90 358 40T229 -11T101 39T50 173V529Q50 611 99 661T228 711Q303 711 351 663T404 532H306Q303 572 282 594T228 617Q192 617 170 595T148 531V424Q185 459 243 459ZM310 174V279Q310 321 287 345T227 369Q192 369 170 345T148 278V174Q148 131 170 107T229 83T287 107T310 174Z"/>
<glyph unicode="7" glyphtame="seven" horizAdvX="370" d="M-4 701H345V634L156 -1H55L235 610H-4V701Z"/>
<glyph unicode="8" glyphtame="eight" horizAdvX="462" d="M341 365Q419 317 419 211V187Q419 95 369 42T231 -11T94 42T43 187V211Q43 261 63 301T120 365Q55 417 55 509V525Q55 611 102 661T231 711T359 661T407 525V509Q407 418 341 365ZM150 522V507Q150 458 171 432T231 406T291 432T312 507V522Q312 617 231 617T150 522ZM323 187V211Q323 262 299 288T231 315T163 289T139 211V187Q139 137 163 111T231 84T299 110T323 187Z"/>
<glyph unicode="9" glyphtame="nine" horizAdvX="445" d="M138 711T216 711T344 661T395 527V171Q395 89 346 39T217 -11Q142 -11 94 37T41 168H139Q142 128 163 106T217 83Q253 83 275 105T297 169V276Q260 241 202 241Q132 241 85 290T37 416V527Q37 610 87 660ZM218 331Q253 331 275 355T297 422V526Q297 569 275 593T216 617T158 593T135 526V421Q135 379 158 355T218 331Z"/>
<glyph unicode=":" glyphtame="colon" horizAdvX="182" d="M122 332T95 332T51 348T34 393Q34 420 51 436T95 453T139 437T156 393Q156 365 139 349ZM122 -11T95 -11T51 5T34 50Q34 77 51 93T95 110T139 94T156 50Q156 22 139 6Z"/>
<glyph unicode=";" glyphtame="semicolon" horizAdvX="178" d="M92 333Q64 333 48 349T31 393Q31 421 47 437T92 454Q119 454 135 438T152 393Q152 366 136 350T92 333ZM47 98H143L86 -136H26L47 98Z"/>
<glyph unicode="&lt;" glyphtame="less" horizAdvX="431" d="M388 488V397L142 303L388 208V117L44 254V351L388 488Z"/>
<glyph unicode="=" glyphtame="equal" horizAdvX="461" d="M43 350V439H417V350H43ZM43 170V258H417V170H43Z"/>
<glyph unicode="&gt;" glyphtame="greater" horizAdvX="431" d="M43 488L387 351V254L43 117V208L289 303L43 397V488Z"/>
<glyph unicode="?" glyphtame="question" horizAdvX="393" d="M131 167V212Q131 262 145 301T180 367T220 416T255 469T270 533Q270 572 249 597T188 622T126 596T102 520H8Q13 609 60 660T187 712T316 663T366 534Q366 489 352 453T317 391T276 341T242 283T227 210V167H131ZM181 -11Q153 -11 137 5T120 50Q120 77 136 93T181 110Q208 110 224 94T241 50Q241 22 225 6T181 -11Z"/>
<glyph unicode="@" glyphtame="at" horizAdvX="797" d="M407 730Q571 730 661 645T751 400V292Q751 198 705 151T593 103Q519 103 490 162Q457 98 382 98Q323 98 288 137T252 250V364Q252 439 286 478T378 517Q440 517 470 467L477 505H539V247Q539 214 551 197T593 179Q672 179 672 292V400Q672 525 604 589T407 653Q272 653 199 583T126 383V262Q126 119 200 42T411 -35Q534 -35 616 15L656 -47Q563 -112 415 -112Q240 -112 143 -14T46 262V383Q46 547 141 638T407 730ZM394 176Q424 176 441 196T459 255V362Q459 395 442 417T393 440Q334 440 334 366V252Q334 176 394 176Z"/>
<glyph unicode="A" glyphtame="A" horizAdvX="476" d="M363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="B" glyphtame="B" horizAdvX="506" d="M375 360Q420 341 446 299T472 200Q472 107 417 54T270 0H70V700H260Q345 700 395 649T446 510Q446 405 375 360ZM248 605H171V404H251Q343 404 343 507Q343 605 248 605ZM257 95Q369 95 369 200Q369 253 338 281T253 309H171V95H257Z"/>
<glyph unicode="C" glyphtame="C" horizAdvX="492" d="M253 -12Q159 -12 105 45T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V206Q153 145 179 115T253 84Q354 84 364 202H464Q459 99 405 44T253 -12Z"/>
<glyph unicode="D" glyphtame="D" horizAdvX="510" d="M70 0V700H244Q346 700 403 643T460 482V220Q460 116 403 58T245 0H70ZM171 96H245Q299 96 328 128T358 222V480Q358 604 245 604H171V96Z"/>
<glyph unicode="E" glyphtame="E" horizAdvX="461" d="M171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="F" glyphtame="F" horizAdvX="425" d="M400 605H171V396H382V304H171V0H70V700H400V605Z"/>
<glyph unicode="G" glyphtame="G" horizAdvX="506" d="M257 -11Q161 -11 106 46T50 208V493Q50 598 104 654T255 711Q349 711 402 656T459 498H359Q353 614 255 614Q153 614 153 492V208Q153 86 258 86Q365 86 365 203V291H254V381H464V203Q463 101 408 45T257 -11Z"/>
<glyph unicode="H" glyphtame="H" horizAdvX="540" d="M369 700H470V0H369V307H171V0H70V700H171V402H369V700Z"/>
<glyph unicode="I" glyphtame="I" horizAdvX="240" d="M70 700H171V0H70V700Z"/>
<glyph unicode="J" glyphtame="J" horizAdvX="284" d="M0 -116V-17Q59 -17 87 12T116 112V700H217V112Q217 1 162 -57T0 -116Z"/>
<glyph unicode="K" glyphtame="K" horizAdvX="503" d="M387 0L216 306L170 237V0H70V700H170V396L216 478L356 700H474L274 395L503 0H387Z"/>
<glyph unicode="L" glyphtame="L" horizAdvX="410" d="M171 96H403V0H70V700H171V96Z"/>
<glyph unicode="M" glyphtame="M" horizAdvX="688" d="M515 700H618V0H519V273Q523 420 527 499L523 501Q503 445 455 317L394 158H294L233 317Q185 445 165 501L161 499Q167 353 169 273V0H70V700H173L291 416Q314 359 342 277H346Q374 359 397 416L515 700Z"/>
<glyph unicode="N" glyphtame="N" horizAdvX="555" d="M385 700H485V0H384L228 343Q185 450 161 513L158 511Q170 391 170 270V0H70V700H171L332 343Q350 303 396 189L399 191Q385 312 385 427V700Z"/>
<glyph unicode="O" glyphtame="O" horizAdvX="528" d="M364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="P" glyphtame="P" horizAdvX="501" d="M255 700Q358 700 414 641T470 474Q470 364 414 305T255 246H171V0H70V700H255ZM254 342Q367 342 367 474Q367 604 254 604H171V342H254Z"/>
<glyph unicode="Q" glyphtame="Q" horizAdvX="529" d="M479 478V222Q479 110 421 49L477 -18L416 -71L354 3Q316 -13 264 -13Q164 -13 107 49T50 222V478Q50 588 107 650T264 713Q365 713 422 651T479 478ZM297 197L353 130Q374 164 374 222V478Q374 614 264 614Q155 614 155 478V222Q155 86 264 86Q277 86 284 87L237 143L297 197Z"/>
<glyph unicode="R" glyphtame="R" horizAdvX="504" d="M375 0L234 254H171V0H70V700H251Q351 700 406 643T461 481Q461 400 428 345T335 268L487 0H375ZM171 604V350H249Q302 350 329 383T357 479Q357 604 250 604H171Z"/>
<glyph unicode="S" glyphtame="S" horizAdvX="465" d="M231 -13Q137 -13 82 42T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 613 90 663T230 713Q319 713 371 660T425 517H322Q321 566 297 591T231 616T165 594T141 534Q141 497 171 467T245 412T331 360T404 286T435 180Q435 98 381 43T231 -13Z"/>
<glyph unicode="T" glyphtame="T" horizAdvX="427" d="M411 700V604H265V0H162V604H17V700H411Z"/>
<glyph unicode="U" glyphtame="U" horizAdvX="530" d="M265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="V" glyphtame="V" horizAdvX="469" d="M179 0L7 700H113L197 314Q206 276 232 128H236Q259 257 271 314L356 700H461L289 0H179Z"/>
<glyph unicode="W" glyphtame="W" horizAdvX="751" d="M639 700H744L587 0H479L411 309Q386 419 378 534H374Q363 411 340 309L272 0H164L7 700H112L184 317Q213 152 216 131H220Q228 187 252 322L322 689H429L499 322Q523 187 531 131H535Q538 152 567 317L639 700Z"/>
<glyph unicode="X" glyphtame="X" horizAdvX="476" d="M461 0H349L239 259L128 0H16L183 363L26 700H137L239 463L340 700H451L294 363L461 0Z"/>
<glyph unicode="Y" glyphtame="Y" horizAdvX="446" d="M173 0V230L3 700H108L187 467Q212 392 222 357H224L260 469L338 700H444L274 230V0H173Z"/>
<glyph unicode="Z" glyphtame="Z" horizAdvX="421" d="M140 96H396V0H17V70L267 604H32V700H391V631L140 96Z"/>
<glyph unicode="[" glyphtame="bracketleft" horizAdvX="298" d="M252 730H166V-30H252V-120H70V820H252V730Z"/>
<glyph unicode="\" glyphtame="backslash" horizAdvX="496" d="M130 813L453 -119H366L44 813H130Z"/>
<glyph unicode="]" glyphtame="bracketright" horizAdvX="298" d="M46 -30H132V730H46V820H228V-120H46V-30Z"/>
<glyph unicode="^" glyphtame="asciicircum" horizAdvX="334" d="M324 475H235L167 621L99 475H10L122 700H212L324 475Z"/>
<glyph unicode="_" glyphtame="underscore" horizAdvX="388" d="M19 -9H369V-102H19V-9Z"/>
<glyph unicode="a" glyphtame="a" horizAdvX="454" d="M225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="b" glyphtame="b" horizAdvX="467" d="M254 540Q331 540 376 486T422 338V193Q422 97 376 43T254 -12Q183 -12 135 48L128 0H58V724H153V498Q195 540 254 540ZM323 193V338Q323 448 238 448Q198 448 176 419T153 338V192Q153 137 176 109T238 80Q323 80 323 193Z"/>
<glyph unicode="c" glyphtame="c" horizAdvX="437" d="M224 -11Q141 -11 94 39T46 182V348Q46 439 93 489T224 540Q309 540 356 490T407 348H309Q306 447 225 447T144 348V182Q144 82 225 82Q308 82 312 182H409Q407 90 358 40T224 -11Z"/>
<glyph unicode="d" glyphtame="d" horizAdvX="467" d="M315 724H409V0H339L332 49Q285 -12 213 -12Q138 -12 92 43T46 193V338Q46 433 91 486T213 540Q274 540 315 497V724ZM315 192V338Q315 389 292 418T229 448Q144 448 144 338V193Q144 80 229 80Q268 80 291 108T315 192Z"/>
<glyph unicode="e" glyphtame="e" horizAdvX="454" d="M320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="f" glyphtame="f" horizAdvX="304" d="M265 650Q223 650 204 628T184 556V527H286V439H184V0H88V439H11V527H88V556Q88 641 132 689T265 738H296V650H265Z"/>
<glyph unicode="g" glyphtame="g" horizAdvX="470" d="M341 527H412V-27Q412 -102 364 -147T235 -193Q156 -193 109 -149T59 -29H154Q160 -103 235 -103Q272 -103 293 -83T314 -27V55Q274 21 215 21Q140 21 93 74T46 214V340Q46 432 92 486T214 540T333 485L341 527ZM231 113Q314 113 314 214V346Q314 395 291 421T231 447Q144 447 144 340V214Q144 113 231 113Z"/>
<glyph unicode="h" glyphtame="h" horizAdvX="469" d="M271 540Q416 540 416 321V0H319V318Q319 387 301 417T245 448Q203 448 179 416T155 326V0H58V722H155V476Q198 540 271 540Z"/>
<glyph unicode="i" glyphtame="i" horizAdvX="213" d="M133 615T106 615T62 632T45 676Q45 703 62 720T106 738T150 721T168 676Q168 650 151 633ZM58 0V527H155V0H58Z"/>
<glyph unicode="j" glyphtame="j" horizAdvX="246" d="M127 614Q100 614 83 631T66 675T83 719T127 737Q153 737 170 720T188 675Q188 649 171 632T127 614ZM-15 -193V-100Q34 -99 56 -75T79 0V527H175V4Q175 -90 127 -141T-15 -193Z"/>
<glyph unicode="k" glyphtame="k" horizAdvX="441" d="M333 0L193 241L154 199V0H58V724H154V321L304 527H419L257 316L444 0H333Z"/>
<glyph unicode="l" glyphtame="l" horizAdvX="209" d="M56 0V724H153V0H56Z"/>
<glyph unicode="m" glyphtame="m" horizAdvX="716" d="M519 540Q587 540 625 487T663 330V0H567V327Q567 448 497 448Q457 448 433 416T409 326V0H313V327Q313 448 241 448Q201 448 178 416T154 326V0H58V527H127L137 468Q179 540 263 540Q349 540 386 460Q427 540 519 540Z"/>
<glyph unicode="n" glyphtame="n" horizAdvX="469" d="M268 540Q338 540 377 488T416 331V0H319V326Q319 390 301 419T245 448Q203 448 179 416T155 326V0H58V527H127L137 468Q180 540 268 540Z"/>
<glyph unicode="o" glyphtame="o" horizAdvX="463" d="M231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="p" glyphtame="p" horizAdvX="467" d="M254 539Q330 539 376 485T422 335V190Q422 95 377 42T254 -12Q193 -12 153 30V-180H58V528H128L135 479Q155 505 187 522T254 539ZM323 190V335Q323 448 238 448Q199 448 176 420T153 336V190Q153 138 175 109T238 79Q323 79 323 190Z"/>
<glyph unicode="q" glyphtame="q" horizAdvX="467" d="M339 528H409V-180H315V30Q273 -12 213 -12Q136 -12 91 41T46 190V335Q46 430 92 484T213 539Q248 539 280 522T332 479L339 528ZM229 79Q269 79 292 108T315 190V336Q315 391 292 419T229 448Q144 448 144 335V190Q144 79 229 79Z"/>
<glyph unicode="r" glyphtame="r" horizAdvX="340" d="M273 539Q308 539 328 531V435Q300 447 263 447Q213 447 184 415T155 325V0H58V527H127L138 466Q181 539 273 539Z"/>
<glyph unicode="s" glyphtame="s" horizAdvX="417" d="M214 -13Q127 -13 77 36T25 162H121Q122 124 145 101T212 77Q250 77 271 94T292 143Q292 168 273 186T225 214T163 240T100 271T52 319T33 393Q33 460 79 500T203 540Q285 540 330 494T378 372H283Q281 452 203 452Q169 452 149 436T129 392Q129 365 156 347T221 315T297 284T362 230T389 142Q389 72 342 30T214 -13Z"/>
<glyph unicode="t" glyphtame="t" horizAdvX="316" d="M259 85H291V-6H254Q176 -6 132 41T87 170V439H13V527H87V652H183V527H281V439H183V169Q183 85 259 85Z"/>
<glyph unicode="u" glyphtame="u" horizAdvX="469" d="M314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="v" glyphtame="v" horizAdvX="406" d="M300 527H402L257 0H149L4 527H107L165 290Q193 159 201 116H206Q214 159 242 290L300 527Z"/>
<glyph unicode="w" glyphtame="w" horizAdvX="636" d="M532 527H632L507 0H402L356 191Q328 315 320 399H316Q309 315 280 191L235 0H130L4 527H105L152 291Q171 189 182 110H186Q198 197 219 295L267 517H370L418 295Q443 165 451 110H455Q461 160 485 291L532 527Z"/>
<glyph unicode="x" glyphtame="x" horizAdvX="407" d="M408 0H298L204 187L109 0H-1L145 264L10 527H117L204 348L290 527H397L264 264L408 0Z"/>
<glyph unicode="y" glyphtame="y" horizAdvX="417" d="M313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="z" glyphtame="z" horizAdvX="366" d="M132 89H348V0H10V65L217 438H26V527H339V463L132 89Z"/>
<glyph unicode="{" glyphtame="braceleft" horizAdvX="338" d="M212 491Q212 401 147 352Q212 303 212 213V57Q212 18 234 -5T293 -28V-120Q213 -120 165 -70T117 66V223Q117 259 98 280T46 305V399Q79 403 98 424T117 482V634Q117 719 165 769T293 820V728Q256 728 234 705T212 643V491Z"/>
<glyph unicode="|" glyphtame="bar" horizAdvX="210" d="M56 800H154V-92H56V800Z"/>
<glyph unicode="}" glyphtame="braceright" horizAdvX="338" d="M221 478Q221 442 240 421T292 396V302Q259 298 240 277T221 219V67Q221 -18 173 -68T45 -119V-27Q82 -27 104 -4T126 58V210Q126 300 191 349Q126 398 126 488V644Q126 683 104 706T45 729V821Q125 821 173 771T221 635V478Z"/>
<glyph unicode="~" glyphtame="asciitilde" horizAdvX="470" d="M380 363L432 301Q391 239 328 239Q294 239 233 262T150 286Q114 286 88 249L38 312Q81 373 141 373Q175 373 235 349T319 325Q355 325 380 363Z"/>
<glyph unicode="¡" glyphtame="exclamdown" horizAdvX="249" d="M125 538Q152 538 168 522T185 478Q185 450 169 434T125 417Q97 417 81 433T64 478Q64 505 80 521T125 538ZM76 360H173V-180H76V360Z"/>
<glyph unicode="¢" glyphtame="cent" horizAdvX="434" d="M406 182Q405 102 367 53T261 -8V-74H182V-7Q118 4 82 53T46 182V345Q46 424 82 473T182 533V599H261V534Q329 523 366 475T404 345H307Q306 444 224 444Q144 444 144 345V182Q144 82 224 82Q306 82 309 182H406Z"/>
<glyph unicode="£" glyphtame="sterling" horizAdvX="478" d="M167 94H446V0H29V84Q91 98 91 179V304H28V390H91V518Q91 605 138 658T263 711Q341 711 389 658T442 512H344Q336 615 263 615Q229 615 209 590T189 519V390H357V304H189V182Q189 131 167 94Z"/>
<glyph unicode="¤" glyphtame="currency" horizAdvX="468" d="M93 85L35 128L87 196Q66 235 66 288V359Q66 412 87 451L35 518L92 562L136 505Q176 534 234 534T332 505L376 562L433 518L381 451Q402 412 402 359V288Q402 235 381 195L433 128L376 85L331 142Q291 113 234 113T137 142L93 85ZM198 199T234 199T290 222T311 289V358Q311 402 291 425T234 448T178 425T157 358V289Q157 245 177 222Z"/>
<glyph unicode="¥" glyphtame="yen" horizAdvX="446" d="M444 700L297 293H430V230H274V169H430V105H274V0H173V105H16V169H173V230H16V293H150L3 700H108L187 467Q212 392 222 357H224L260 469L338 700H444Z"/>
<glyph unicode="¦" glyphtame="brokenbar" horizAdvX="190" d="M46 495V800H144V495H46ZM46 -82V223H144V-82H46Z"/>
<glyph unicode="§" glyphtame="section" horizAdvX="422" d="M326 215Q384 169 384 101Q384 30 338 -12T212 -54Q127 -54 79 -5T29 120H125Q126 83 147 60T210 36Q247 36 267 53T287 102Q287 130 260 150T195 186T118 219T53 272T26 356Q26 442 101 481Q42 524 42 595Q42 661 87 699T206 738Q285 738 328 693T374 572H280Q277 650 206 650Q174 650 156 635T138 594Q138 568 164 549T227 514T302 480T366 425T392 336Q392 255 326 215ZM236 270Q264 274 279 291T295 331Q295 363 270 384T198 424Q166 424 144 407T122 359Q122 330 149 310T236 270Z"/>
<glyph unicode="©" glyphtame="copyright" horizAdvX="696" d="M349 -9Q208 -9 129 76T50 314V395Q50 546 129 629T349 713Q489 713 567 630T646 395V314Q646 161 568 76T349 -9ZM349 55Q460 55 518 122T577 314V395Q577 518 519 583T349 649Q237 649 178 584T119 395V314Q119 188 178 122T349 55ZM347 148Q287 148 252 185T217 289V414Q217 480 252 517T347 554Q408 554 442 518T477 414H406Q405 484 348 484Q289 484 289 414V289Q289 219 348 219Q406 219 409 289H480Q479 223 444 186T347 148Z"/>
<glyph unicode="«" glyphtame="guillemotleft" horizAdvX="469" d="M155 87L38 239V275L155 427H250L121 257L250 87H155ZM335 87L218 239V275L335 427H430L301 257L430 87H335Z"/>
<glyph unicode="¬" glyphtame="logicalnot" horizAdvX="470" d="M430 354V191H342V264H48V354H430Z"/>
<glyph unicode="®" glyphtame="registered" horizAdvX="459" d="M233 713Q317 713 364 664T411 526V468Q411 375 364 325T233 274Q148 274 101 324T53 468V526Q53 615 100 664T233 713ZM374 468V525Q374 599 338 638T233 677Q164 677 128 639T91 525V468Q91 390 127 350T233 309Q301 309 337 349T374 468ZM312 539Q312 482 268 466L321 370H277L228 460H207V370H167V618H235Q272 618 292 598T312 539ZM207 579V498H235Q272 498 272 539Q272 579 235 579H207Z"/>
<glyph unicode="°" glyphtame="degree" horizAdvX="313" d="M26 519T26 579T62 675T157 712T251 676T287 579T252 483T157 446T62 482ZM93 610T93 579T110 530T157 511Q185 511 202 529T220 579T203 628T157 647Q128 647 111 629Z"/>
<glyph unicode="±" glyphtame="plusminus" horizAdvX="470" d="M280 408H422V320H280V183H190V320H48V408H190V545H280V408ZM48 53V138H422V53H48Z"/>
<glyph unicode="¶" glyphtame="paragraph" horizAdvX="599" d="M529 702V-92H431V610H307V-92H209V383Q136 383 91 426T46 543Q46 616 91 659T209 702H529Z"/>
<glyph unicode="·" glyphtame="middot" horizAdvX="194" d="M124 197T97 197T53 213T36 258Q36 285 53 301T97 318T141 302T158 258Q158 230 141 214Z"/>
<glyph unicode="»" glyphtame="guillemotright" horizAdvX="469" d="M134 87H39L168 257L39 427H134L251 275V239L134 87ZM314 87H219L348 257L219 427H314L431 275V239L314 87Z"/>
<glyph unicode="¿" glyphtame="questiondown" horizAdvX="393" d="M180 537T207 537T251 521T268 477T251 433T207 416T163 433T146 477T163 520ZM256 359V314Q256 264 242 225T207 160T166 111T132 58T117 -7Q117 -46 139 -71T200 -96T261 -69T285 7H380Q375 -82 327 -133T200 -185T71 -136T21 -7Q21 37 35 73T70 135T110 185T145 243T160 316V359H256Z"/>
<glyph unicode="À" glyphtame="Agrave" horizAdvX="476" d="M302 768H226L117 893H209L302 768ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Á" glyphtame="Aacute" horizAdvX="476" d="M364 893L255 768H179L272 893H364ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Â" glyphtame="Acircumflex" horizAdvX="476" d="M238 838L187 768H115L196 893H279L360 768H288L238 838ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Ã" glyphtame="Atilde" horizAdvX="476" d="M208 818Q188 818 184 791H119Q121 834 142 858T198 883Q222 883 245 868T276 853Q297 853 302 880H365Q361 837 340 813T286 788Q263 788 240 803T208 818ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Ä" glyphtame="Adieresis" horizAdvX="476" d="M187 782T164 782T128 795T114 831Q114 854 127 867T164 881T200 868T214 831Q214 809 201 796ZM333 782T310 782T273 795T259 831Q259 854 273 867T310 881T346 868T360 831Q360 809 347 796ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Å" glyphtame="Aring" horizAdvX="476" d="M238 764Q202 764 180 786T158 843T180 899T238 920Q275 920 297 899T319 843T297 786T238 764ZM252 874T238 874T215 865T206 842T215 819T238 810T261 819T270 842T261 865ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Æ" glyphtame="AE" horizAdvX="703" d="M432 95H665V0H331V151H168L109 0H0L283 700H655V605H432V402H636V309H432V95ZM205 245H331V589H328Q304 505 279 433L205 245Z"/>
<glyph unicode="Ç" glyphtame="Ccedilla" horizAdvX="492" d="M464 202Q460 108 414 54T287 -10L276 -49Q279 -48 288 -48Q319 -48 339 -67T360 -119Q360 -159 332 -182T259 -206Q220 -206 192 -184T160 -124H220Q230 -151 260 -151Q299 -151 299 -117Q299 -85 263 -85Q244 -85 230 -93L211 -75L230 -11Q146 -4 98 52T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V206Q153 145 179 115T253 84Q354 84 364 202H464Z"/>
<glyph unicode="È" glyphtame="Egrave" horizAdvX="461" d="M304 768H228L119 893H211L304 768ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="É" glyphtame="Eacute" horizAdvX="461" d="M366 893L257 768H181L274 893H366ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="Ê" glyphtame="Ecircumflex" horizAdvX="461" d="M240 838L189 768H117L198 893H281L362 768H290L240 838ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="Ë" glyphtame="Edieresis" horizAdvX="461" d="M189 782T166 782T130 795T116 831Q116 854 129 867T166 881T202 868T216 831Q216 809 203 796ZM335 782T312 782T275 795T261 831Q261 854 275 867T312 881T348 868T362 831Q362 809 349 796ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="Ì" glyphtame="Igrave" horizAdvX="240" d="M187 768H111L2 893H94L187 768ZM70 700H171V0H70V700Z"/>
<glyph unicode="Í" glyphtame="Iacute" horizAdvX="240" d="M64 768L157 893H249L140 768H64ZM70 700H171V0H70V700Z"/>
<glyph unicode="Î" glyphtame="Icircumflex" horizAdvX="240" d="M164 893L245 768H173L123 838L72 768H0L81 893H164ZM70 0V700H171V0H70Z"/>
<glyph unicode="Ï" glyphtame="Idieresis" horizAdvX="250" d="M81 782T58 782T22 795T8 831Q8 854 21 867T58 881T94 868T108 831Q108 809 95 796ZM215 782T192 782T156 795T142 831Q142 854 155 867T192 881T229 868T243 831Q243 809 229 796ZM73 700H174V0H73V700Z"/>
<glyph unicode="Ð" glyphtame="Eth" horizAdvX="521" d="M250 700Q353 700 412 645T471 492V210Q471 110 412 55T251 0H80V322H9V391H80V700H250ZM368 212V490Q368 604 255 604H181V391H265V322H181V96H255Q368 96 368 212Z"/>
<glyph unicode="Ñ" glyphtame="Ntilde" horizAdvX="555" d="M248 818Q228 818 224 791H159Q161 834 182 858T238 883Q262 883 285 868T316 853Q337 853 342 880H405Q401 837 380 813T326 788Q303 788 280 803T248 818ZM385 700H485V0H384L228 343Q185 450 161 513L158 511Q170 391 170 270V0H70V700H171L332 343Q350 303 396 189L399 191Q385 312 385 427V700Z"/>
<glyph unicode="Ò" glyphtame="Ograve" horizAdvX="528" d="M328 768H252L143 893H235L328 768ZM364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="Ó" glyphtame="Oacute" horizAdvX="528" d="M205 768L298 893H390L281 768H205ZM364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="Ô" glyphtame="Ocircumflex" horizAdvX="528" d="M264 838L213 768H141L222 893H305L386 768H314L264 838ZM164 713T264 713T421 651T478 478V222Q478 112 421 50T264 -13T107 49T50 222V478Q50 588 107 650ZM374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Q318 86 346 121T374 222Z"/>
<glyph unicode="Õ" glyphtame="Otilde" horizAdvX="528" d="M234 818Q214 818 210 791H145Q147 834 168 858T224 883Q248 883 271 868T302 853Q323 853 328 880H391Q387 837 366 813T312 788Q289 788 266 803T234 818ZM364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="Ö" glyphtame="Odieresis" horizAdvX="528" d="M213 782T190 782T154 795T140 831Q140 854 153 867T190 881T226 868T240 831Q240 809 227 796ZM359 782T336 782T299 795T285 831Q285 854 299 867T336 881T372 868T386 831Q386 809 373 796ZM364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="×" glyphtame="multiply" horizAdvX="463" d="M102 107L39 171L168 300L40 428L102 492L232 363L361 492L424 428L296 300L424 171L361 107L232 236L102 107Z"/>
<glyph unicode="Ø" glyphtame="Oslash" horizAdvX="526" d="M403 665Q475 602 475 478V222Q475 113 419 51T263 -12Q230 -12 200 -4L182 -64H100L129 30Q50 92 50 222V478Q50 587 106 649T263 712Q300 712 332 703L351 764H433L403 665ZM155 222Q155 179 165 152L304 607Q287 614 263 614Q155 614 155 478V222ZM371 222V478Q371 512 364 536L229 90Q242 86 263 86Q316 86 343 121T371 222Z"/>
<glyph unicode="Ù" glyphtame="Ugrave" horizAdvX="530" d="M330 768H254L145 893H237L330 768ZM265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="Ú" glyphtame="Uacute" horizAdvX="530" d="M207 768L300 893H392L283 768H207ZM265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="Û" glyphtame="Ucircumflex" horizAdvX="530" d="M266 838L215 768H143L224 893H307L388 768H316L266 838ZM367 700H471V216Q471 109 416 50T265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700Z"/>
<glyph unicode="Ü" glyphtame="Udieresis" horizAdvX="530" d="M215 782T192 782T156 795T142 831Q142 854 155 867T192 881T228 868T242 831Q242 809 229 796ZM361 782T338 782T301 795T287 831Q287 854 301 867T338 881T374 868T388 831Q388 809 375 796ZM265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="Ý" glyphtame="Yacute" horizAdvX="446" d="M165 768L258 893H350L241 768H165ZM173 0V230L3 700H108L187 467Q212 392 222 357H224L260 469L338 700H444L274 230V0H173Z"/>
<glyph unicode="Þ" glyphtame="Thorn" horizAdvX="489" d="M255 589Q358 589 414 530T470 363Q470 253 414 194T255 134H171V0H70V700H171V589H255ZM254 230Q367 230 367 363Q367 493 254 493H171V230H254Z"/>
<glyph unicode="ß" glyphtame="germandbls" horizAdvX="479" d="M356 365Q400 344 425 301T451 201Q451 108 397 54T254 0H207V96H241Q347 96 347 202Q347 314 243 314H207V407H234Q326 407 326 511Q326 618 242 618T158 507V0H58V507Q58 602 107 657T242 713T377 658T427 510Q427 409 356 365Z"/>
<glyph unicode="à" glyphtame="agrave" horizAdvX="454" d="M290 610H214L125 734H215L290 610ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="á" glyphtame="aacute" horizAdvX="454" d="M331 734L242 610H166L241 734H331ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="â" glyphtame="acircumflex" horizAdvX="454" d="M225 679L174 609H102L183 734H266L347 609H275L225 679ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="ã" glyphtame="atilde" horizAdvX="454" d="M191 648Q172 648 167 621H102Q104 664 125 688T181 713Q204 713 227 698T259 683Q280 683 285 710H348Q344 667 323 643T269 618Q246 618 223 633T191 648ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="ä" glyphtame="adieresis" horizAdvX="454" d="M178 622T155 622T119 636T105 672T118 708T155 722T191 708T205 672T192 636ZM318 622T295 622T258 635T244 672T258 708T295 722T331 708T345 672T332 636ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="å" glyphtame="aring" horizAdvX="454" d="M225 606Q189 606 167 628T145 685T167 741T225 762Q262 762 284 741T306 685T284 628T225 606ZM239 716T225 716T202 707T193 684T202 661T225 652T248 661T257 684T248 707ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="æ" glyphtame="ae" horizAdvX="691" d="M557 169H654Q650 84 602 37T471 -11Q388 -11 339 53Q297 -11 190 -11Q118 -11 75 33T31 151Q31 230 82 270T225 310H295V355Q295 446 223 446Q189 446 168 429T143 377H46Q50 453 98 496T225 540T347 480Q392 540 471 540Q554 540 602 489T650 344V232H391V187Q391 82 472 82Q512 82 532 103T557 169ZM391 345V304H554V345Q554 449 472 449Q391 449 391 345ZM295 156V228H225Q129 228 129 150Q129 114 151 94T214 73Q252 73 273 95T295 156Z"/>
<glyph unicode="ç" glyphtame="ccedilla" horizAdvX="437" d="M409 182Q407 98 366 49T252 -9L241 -49Q244 -48 253 -48Q284 -48 304 -67T325 -119Q325 -159 297 -182T224 -206Q185 -206 157 -184T125 -124H185Q195 -151 225 -151Q264 -151 264 -117Q264 -85 228 -85Q209 -85 195 -93L176 -75L195 -9Q125 -1 86 49T46 182V348Q46 439 93 489T224 540Q309 540 356 490T407 348H309Q306 447 225 447T144 348V182Q144 82 225 82Q308 82 312 182H409Z"/>
<glyph unicode="è" glyphtame="egrave" horizAdvX="454" d="M291 608H215L126 732H216L291 608ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="é" glyphtame="eacute" horizAdvX="454" d="M332 732L243 608H167L242 732H332ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="ê" glyphtame="ecircumflex" horizAdvX="454" d="M226 677L175 607H103L184 732H267L348 607H276L226 677ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="ë" glyphtame="edieresis" horizAdvX="454" d="M178 620T156 620T120 634T106 670T120 706T156 720T192 706T206 670T192 634ZM296 620Q273 620 259 633T245 670T259 706T296 720Q318 720 332 706T346 670T332 634T296 620ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="ì" glyphtame="igrave" horizAdvX="213" d="M7 734H97L172 610H96L7 734ZM58 0V527H155V0H58Z"/>
<glyph unicode="í" glyphtame="iacute" horizAdvX="213" d="M213 734L124 610H48L123 734H213ZM58 0V527H155V0H58Z"/>
<glyph unicode="î" glyphtame="icircumflex" horizAdvX="234" d="M158 734L236 609H165L118 678L69 609H-1L77 734H158ZM68 0V527H165V0H68Z"/>
<glyph unicode="ï" glyphtame="idieresis" horizAdvX="232" d="M73 624T51 624T16 637T3 672T16 707T51 721T86 708T99 672T86 637ZM203 624T181 624T146 637T132 672T145 707T181 721T216 708T230 672T217 637ZM67 0V527H164V0H67Z"/>
<glyph unicode="ð" glyphtame="eth" horizAdvX="459" d="M343 627Q412 537 412 402V189Q412 93 363 41T228 -11Q143 -11 95 41T46 189V309Q46 404 92 456T217 509Q272 509 309 479Q296 538 258 583L182 544L165 582L226 613Q190 642 142 657L176 736Q257 714 314 658L380 692L398 655L343 627ZM314 189V309Q314 415 229 415T144 309V189Q144 83 228 83Q314 83 314 189Z"/>
<glyph unicode="ñ" glyphtame="ntilde" horizAdvX="469" d="M201 646Q182 646 177 619H112Q114 662 135 686T191 711Q214 711 237 696T269 681Q290 681 295 708H358Q354 665 333 641T279 616Q256 616 233 631T201 646ZM268 540Q338 540 377 488T416 331V0H319V326Q319 390 301 419T245 448Q203 448 179 416T155 326V0H58V527H127L137 468Q180 540 268 540Z"/>
<glyph unicode="ò" glyphtame="ograve" horizAdvX="463" d="M131 734H221L296 610H220L131 734ZM231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="ó" glyphtame="oacute" horizAdvX="463" d="M337 734L248 610H172L247 734H337ZM231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="ô" glyphtame="ocircumflex" horizAdvX="463" d="M231 679L180 609H108L189 734H272L353 609H281L231 679ZM231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540ZM319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Q319 82 319 188Z"/>
<glyph unicode="õ" glyphtame="otilde" horizAdvX="463" d="M197 648Q178 648 173 621H108Q110 664 131 688T187 713Q211 713 233 698T265 683Q286 683 291 710H354Q350 667 329 643T275 618Q252 618 229 633T197 648ZM231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="ö" glyphtame="odieresis" horizAdvX="463" d="M184 622T161 622T125 636T111 672T124 708T161 722T197 708T211 672T198 636ZM324 622T301 622T264 635T250 672T264 708T301 722T337 708T351 672T338 636ZM231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="÷" glyphtame="divide" horizAdvX="461" d="M256 399T231 399T190 415T173 457Q173 483 189 499T231 516T273 500T289 457Q289 432 273 416ZM43 263V348H417V263H43ZM256 89T231 89T190 105T173 147T189 188T231 205T272 189T289 147T273 106Z"/>
<glyph unicode="ø" glyphtame="oslash" horizAdvX="463" d="M360 496Q417 442 417 340V189Q417 93 368 41T231 -11Q193 -11 164 -1L138 -67H67L106 31Q46 83 46 189V340Q46 436 95 488T231 540Q269 540 302 529L328 596H399L360 496ZM144 189Q144 162 150 143L267 440Q250 446 231 446Q144 446 144 340V189ZM319 189V340Q319 366 315 382L199 88Q216 83 231 83Q319 83 319 189Z"/>
<glyph unicode="ù" glyphtame="ugrave" horizAdvX="469" d="M297 608H221L132 732H222L297 608ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="ú" glyphtame="uacute" horizAdvX="469" d="M338 732L249 608H173L248 732H338ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="û" glyphtame="ucircumflex" horizAdvX="469" d="M232 677L181 607H109L190 732H273L354 607H282L232 677ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="ü" glyphtame="udieresis" horizAdvX="469" d="M184 620T162 620T126 634T112 670T126 706T162 720T198 706T212 670T198 634ZM302 620Q279 620 265 633T251 670T265 706T302 720Q324 720 338 706T352 670T338 634T302 620ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="ý" glyphtame="yacute" horizAdvX="417" d="M312 736L223 612H147L222 736H312ZM313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="þ" glyphtame="thorn" horizAdvX="465" d="M254 545Q330 545 374 491T419 342V200Q419 105 375 52T254 -2Q195 -2 153 40V-180H58V722H153V502Q194 545 254 545ZM321 200V342Q321 453 238 453Q198 453 176 424T153 342V200Q153 149 175 119T238 89Q321 89 321 200Z"/>
<glyph unicode="ÿ" glyphtame="ydieresis" horizAdvX="417" d="M158 624T136 624T100 638T86 674T100 710T136 724T172 710T186 674T172 638ZM276 624Q253 624 239 637T225 674T239 710T276 724Q298 724 312 710T326 674T312 638T276 624ZM313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="Ā" glyphtame="Amacron" horizAdvX="476" d="M340 862V793H138V862H340ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="ā" glyphtame="amacron" horizAdvX="454" d="M326 704V636H124V704H326ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="Ă" glyphtame="Abreve" horizAdvX="476" d="M292 768T239 768T153 800T118 888H187Q190 835 238 835Q287 835 290 888H359Q357 831 325 800ZM363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="ă" glyphtame="abreve" horizAdvX="454" d="M279 609T226 609T140 641T105 729H174Q177 676 225 676Q274 676 277 729H346Q344 672 312 641ZM225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="Ą" glyphtame="Aogonek" horizAdvX="476" d="M430 -116Q447 -116 459 -113L460 -184Q438 -193 398 -193Q353 -193 329 -170T305 -110Q305 -51 363 0L330 141H145L113 0H7L183 700H292L468 0H447Q424 -18 410 -42T395 -85Q395 -116 430 -116ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="ą" glyphtame="aogonek" horizAdvX="456" d="M340 -193Q294 -193 272 -169T249 -108Q249 -51 319 0L312 49Q275 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T223 313H301V350Q301 447 224 447Q191 447 169 429T143 377H46Q50 453 98 496T226 540Q311 540 354 491T398 350V0Q369 -20 351 -43T333 -87Q333 -117 366 -117Q386 -117 398 -113V-184Q375 -193 340 -193ZM174 73T214 73T277 96T301 157V229H223Q129 229 129 150Q129 114 151 94Z"/>
<glyph unicode="Ć" glyphtame="Cacute" horizAdvX="492" d="M189 768L282 893H374L265 768H189ZM253 -12Q159 -12 105 45T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V206Q153 145 179 115T253 84Q354 84 364 202H464Q459 99 405 44T253 -12Z"/>
<glyph unicode="ć" glyphtame="cacute" horizAdvX="437" d="M332 734L243 610H167L242 734H332ZM224 -11Q141 -11 94 39T46 182V348Q46 439 93 489T224 540Q309 540 356 490T407 348H309Q306 447 225 447T144 348V182Q144 82 225 82Q308 82 312 182H409Q407 90 358 40T224 -11Z"/>
<glyph unicode="Ċ" glyphtame="Cdotaccent" horizAdvX="492" d="M247 778Q219 778 202 795T185 840Q185 866 202 883T247 901Q273 901 290 884T308 840T291 796T247 778ZM253 -12Q159 -12 105 45T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V206Q153 145 179 115T253 84Q354 84 364 202H464Q459 99 405 44T253 -12Z"/>
<glyph unicode="ċ" glyphtame="cdotaccent" horizAdvX="437" d="M252 615T225 615T181 632T164 676Q164 703 181 720T225 738T269 721T287 676Q287 650 270 633ZM224 -11Q141 -11 94 39T46 182V348Q46 439 93 489T224 540Q309 540 356 490T407 348H309Q306 447 225 447T144 348V182Q144 82 225 82Q308 82 312 182H409Q407 90 358 40T224 -11Z"/>
<glyph unicode="Č" glyphtame="Ccaron" horizAdvX="492" d="M289 768H206L125 893H197L248 823L298 893H370L289 768ZM364 202H464Q459 99 405 44T253 -12Q159 -12 105 45T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V206Q153 145 179 115T253 84Q354 84 364 202Z"/>
<glyph unicode="č" glyphtame="ccaron" horizAdvX="437" d="M267 609H184L103 734H175L226 664L276 734H348L267 609ZM312 182H409Q407 90 358 40T224 -11Q141 -11 94 39T46 182V348Q46 439 93 489T224 540Q309 540 356 490T407 348H309Q306 447 225 447T144 348V182Q144 82 225 82Q308 82 312 182Z"/>
<glyph unicode="Ď" glyphtame="Dcaron" horizAdvX="510" d="M283 768H200L119 893H191L242 823L292 893H364L283 768ZM244 700Q346 700 403 643T460 482V220Q460 116 403 58T245 0H70V700H244ZM358 222V480Q358 604 245 604H171V96H245Q299 96 328 128T358 222Z"/>
<glyph unicode="ď" glyphtame="dcaron" horizAdvX="534" d="M472 772H560L519 587H459L472 772ZM315 497V724H409V0H339L332 49Q285 -12 213 -12Q138 -12 92 43T46 193V338Q46 433 91 486T213 540Q274 540 315 497ZM315 192V338Q315 389 292 418T229 448Q144 448 144 338V193Q144 80 229 80Q268 80 291 108T315 192Z"/>
<glyph unicode="Đ" glyphtame="Dcroat" horizAdvX="521" d="M250 700Q353 700 412 645T471 492V210Q471 110 412 55T251 0H80V322H9V391H80V700H250ZM368 212V490Q368 604 255 604H181V391H265V322H181V96H255Q368 96 368 212Z"/>
<glyph unicode="đ" glyphtame="" horizAdvX="473" d="M473 657V601H409V0H339L332 49Q285 -12 213 -12Q138 -12 92 43T46 193V338Q46 433 91 486T213 540Q274 540 315 497V601H217V657H315V724H409V657H473ZM315 192V338Q315 389 292 418T229 448Q144 448 144 338V193Q144 80 229 80Q268 80 291 108T315 192Z"/>
<glyph unicode="Ē" glyphtame="Emacron" horizAdvX="461" d="M342 862V793H140V862H342ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="ē" glyphtame="emacron" horizAdvX="454" d="M327 702V634H125V702H327ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="Ė" glyphtame="Edotaccent" horizAdvX="461" d="M239 778Q211 778 194 795T177 840Q177 866 194 883T239 901Q265 901 282 884T300 840T283 796T239 778ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="ė" glyphtame="edotaccent" horizAdvX="454" d="M252 613T225 613T181 630T164 674Q164 701 181 718T225 736T269 719T287 674Q287 648 270 631ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="Ę" glyphtame="Eogonek" horizAdvX="465" d="M427 -113L428 -184Q407 -193 366 -193Q321 -193 298 -170T274 -110Q274 -51 340 0H70V700H413V605H171V402H394V309H171V95H423V0Q397 -18 380 -41T363 -83Q363 -116 398 -116Q415 -116 427 -113Z"/>
<glyph unicode="ę" glyphtame="eogonek" horizAdvX="454" d="M305 -193Q260 -193 236 -170T212 -109Q212 -58 265 -8V-7Q243 -11 226 -11Q142 -11 94 41T46 187V344Q46 436 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 228 82Q271 82 292 102T320 165H417Q409 88 361 36Q296 -32 296 -76Q296 -116 334 -116Q354 -116 367 -113V-184Q340 -193 305 -193ZM143 300H314V345Q314 449 227 449Q143 449 143 345V300Z"/>
<glyph unicode="Ě" glyphtame="Ecaron" horizAdvX="461" d="M281 768H198L117 893H189L240 823L290 893H362L281 768ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="ě" glyphtame="ecaron" horizAdvX="454" d="M267 607H184L103 732H175L226 662L276 732H348L267 607ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="Ğ" glyphtame="Gbreve" horizAdvX="506" d="M305 768T252 768T166 800T131 888H200Q203 835 251 835Q300 835 303 888H372Q370 831 338 800ZM257 -11Q161 -11 106 46T50 208V493Q50 598 104 654T255 711Q349 711 402 656T459 498H359Q353 614 255 614Q153 614 153 492V208Q153 86 258 86Q365 86 365 203V291H254V381H464V203Q463 101 408 45T257 -11Z"/>
<glyph unicode="ğ" glyphtame="gbreve" horizAdvX="470" d="M284 609T231 609T145 641T110 729H179Q182 676 230 676Q279 676 282 729H351Q349 672 317 641ZM341 527H412V-27Q412 -102 364 -147T235 -193Q156 -193 109 -149T59 -29H154Q160 -103 235 -103Q272 -103 293 -83T314 -27V55Q274 21 215 21Q140 21 93 74T46 214V340Q46 432 92 486T214 540T333 485L341 527ZM231 113Q314 113 314 214V346Q314 395 291 421T231 447Q144 447 144 340V214Q144 113 231 113Z"/>
<glyph unicode="Ġ" glyphtame="Gdotaccent" horizAdvX="506" d="M250 778Q222 778 205 795T188 840Q188 866 205 883T250 901Q276 901 293 884T311 840T294 796T250 778ZM257 -11Q161 -11 106 46T50 208V493Q50 598 104 654T255 711Q349 711 402 656T459 498H359Q353 614 255 614Q153 614 153 492V208Q153 86 258 86Q365 86 365 203V291H254V381H464V203Q463 101 408 45T257 -11Z"/>
<glyph unicode="ġ" glyphtame="gdotaccent" horizAdvX="470" d="M256 615T229 615T185 632T168 676Q168 703 185 720T229 738T273 721T291 676Q291 650 274 633ZM341 527H412V-27Q412 -102 364 -147T235 -193Q156 -193 109 -149T59 -29H154Q160 -103 235 -103Q272 -103 293 -83T314 -27V55Q274 21 215 21Q140 21 93 74T46 214V340Q46 432 92 486T214 540T333 485L341 527ZM231 113Q314 113 314 214V346Q314 395 291 421T231 447Q144 447 144 340V214Q144 113 231 113Z"/>
<glyph unicode="Ģ" glyphtame="Gcommaaccent" horizAdvX="506" d="M257 -11Q161 -11 106 46T50 208V493Q50 598 104 654T255 711Q349 711 402 656T459 498H359Q353 614 255 614Q153 614 153 492V208Q153 86 258 86Q365 86 365 203V291H254V381H464V203Q463 101 408 45T257 -11ZM193 -265L210 -70H298L255 -265H193Z"/>
<glyph unicode="ģ" glyphtame="gcommaaccent" horizAdvX="470" d="M275 604H179L161 800H224L275 604ZM341 527H412V-27Q412 -102 364 -147T235 -193Q156 -193 109 -149T59 -29H154Q160 -103 235 -103Q272 -103 293 -83T314 -27V55Q274 21 215 21Q140 21 93 74T46 214V340Q46 432 92 486T214 540T333 485L341 527ZM231 113Q314 113 314 214V346Q314 395 291 421T231 447Q144 447 144 340V214Q144 113 231 113Z"/>
<glyph unicode="Ħ" glyphtame="Hbar" horizAdvX="570" d="M552 579V524H485V0H384V307H186V0H85V524H17V579H85V700H186V579H384V700H485V579H552ZM384 402V524H186V402H384Z"/>
<glyph unicode="ħ" glyphtame="hbar" horizAdvX="467" d="M269 540Q415 540 415 321V0H318V318Q318 387 300 417T243 448Q202 448 178 416T153 326V0H57V605H-7V660H57V724H153V660H247V605H153V475Q195 540 269 540Z"/>
<glyph unicode="Ī" glyphtame="Imacron" horizAdvX="240" d="M23 862H225V793H23V862ZM70 700H171V0H70V700Z"/>
<glyph unicode="ī" glyphtame="imacron" horizAdvX="210" d="M13 704H198V634H13V704ZM57 0V527H154V0H57Z"/>
<glyph unicode="Į" glyphtame="Iogonek" horizAdvX="239" d="M158 -115Q179 -115 188 -112V-183Q159 -193 125 -193Q76 -193 52 -169T28 -105Q28 -46 80 0H69V700H170V0H158Q140 -18 128 -41T116 -82Q116 -115 158 -115Z"/>
<glyph unicode="į" glyphtame="iogonek" horizAdvX="211" d="M127 617T103 617T63 633T47 673Q47 698 62 714T103 730Q127 730 143 714T160 673Q160 650 144 634ZM140 -116Q156 -116 170 -113V-184Q149 -193 104 -193T35 -170T11 -110Q11 -52 54 -1V525H151V0L137 -1Q100 -42 100 -80Q100 -116 140 -116Z"/>
<glyph unicode="İ" glyphtame="Idot" horizAdvX="240" d="M122 778Q94 778 77 795T60 840Q60 866 77 883T122 901Q148 901 165 884T183 840T166 796T122 778ZM70 700H171V0H70V700Z"/>
<glyph unicode="ı" glyphtame="dotlessi" horizAdvX="213" d="M58 0V527H155V0H58Z"/>
<glyph unicode="Ķ" glyphtame="Kcommaaccent" horizAdvX="503" d="M274 395L503 0H387L216 306L170 237V0H70V700H170V396L216 478L356 700H474L274 395ZM194 -265L211 -70H299L256 -265H194Z"/>
<glyph unicode="ķ" glyphtame="kcommaaccent" horizAdvX="441" d="M257 316L444 0H333L193 241L154 199V0H58V724H154V321L304 527H419L257 316ZM171 -265L188 -70H276L233 -265H171Z"/>
<glyph unicode="Ĺ" glyphtame="Lacute" horizAdvX="410" d="M305 893L196 768H120L213 893H305ZM171 96H403V0H70V700H171V96Z"/>
<glyph unicode="ĺ" glyphtame="lacute" horizAdvX="214" d="M47 768L140 893H232L123 768H47ZM56 0V724H153V0H56Z"/>
<glyph unicode="Ļ" glyphtame="Lcommaaccent" horizAdvX="410" d="M171 96H403V0H70V700H171V96ZM152 -265L169 -70H257L214 -265H152Z"/>
<glyph unicode="ļ" glyphtame="lcommaaccent" horizAdvX="209" d="M56 0V724H153V0H56ZM55 -265L72 -70H160L117 -265H55Z"/>
<glyph unicode="Ľ" glyphtame="Lcaron" horizAdvX="404" d="M171 95H388V0H70V700H171V95ZM366 700L326 499H266L276 700H366Z"/>
<glyph unicode="ľ" glyphtame="lcaron" horizAdvX="278" d="M202 584L215 771H304L262 584H202ZM56 0V724H153V0H56Z"/>
<glyph unicode="Ł" glyphtame="Lslash" horizAdvX="430" d="M199 95H423V0H98V300L22 271V358L98 387V700H199V425L322 472V385L199 338V95Z"/>
<glyph unicode="ł" glyphtame="lslash" horizAdvX="258" d="M246 484V405L176 381V0H80V349L12 326V405L80 428V722H176V460L246 484Z"/>
<glyph unicode="Ń" glyphtame="Nacute" horizAdvX="555" d="M219 768L312 893H404L295 768H219ZM385 700H485V0H384L228 343Q185 450 161 513L158 511Q170 391 170 270V0H70V700H171L332 343Q350 303 396 189L399 191Q385 312 385 427V700Z"/>
<glyph unicode="ń" glyphtame="nacute" horizAdvX="469" d="M341 732L252 608H176L251 732H341ZM268 540Q338 540 377 488T416 331V0H319V326Q319 390 301 419T245 448Q203 448 179 416T155 326V0H58V527H127L137 468Q180 540 268 540Z"/>
<glyph unicode="Ņ" glyphtame="Ncommaaccent" horizAdvX="555" d="M385 700H485V0H384L228 343Q185 450 161 513L158 511Q170 391 170 270V0H70V700H171L332 343Q350 303 396 189L399 191Q385 312 385 427V700ZM220 -265L237 -70H325L282 -265H220Z"/>
<glyph unicode="ņ" glyphtame="ncommaaccent" horizAdvX="469" d="M268 540Q338 540 377 488T416 331V0H319V326Q319 390 301 419T245 448Q203 448 179 416T155 326V0H58V527H127L137 468Q180 540 268 540ZM170 -265L187 -70H275L232 -265H170Z"/>
<glyph unicode="Ň" glyphtame="Ncaron" horizAdvX="555" d="M319 768H236L155 893H227L278 823L328 893H400L319 768ZM385 700H485V0H384L228 343Q185 450 161 513L158 511Q170 391 170 270V0H70V700H171L332 343Q350 303 396 189L399 191Q385 312 385 427V700Z"/>
<glyph unicode="ň" glyphtame="ncaron" horizAdvX="469" d="M276 607H193L112 732H184L235 662L285 732H357L276 607ZM268 540Q338 540 377 488T416 331V0H319V326Q319 390 301 419T245 448Q203 448 179 416T155 326V0H58V527H127L137 468Q180 540 268 540Z"/>
<glyph unicode="Ŋ" glyphtame="Eng" horizAdvX="555" d="M385 700H485V-6Q485 -94 438 -143T300 -193V-100Q388 -99 388 -10V-9L226 347Q195 424 161 513L158 511Q170 391 170 270V0H70V700H171L336 339Q372 248 397 181L400 182Q385 315 385 425V700Z"/>
<glyph unicode="ŋ" glyphtame="eng" horizAdvX="469" d="M268 540Q416 540 416 331V-13Q416 -101 369 -150T231 -200V-107Q319 -106 319 -17V326Q319 390 301 419T245 448Q203 448 179 416T155 326V0H58V527H127L137 468Q180 540 268 540Z"/>
<glyph unicode="Ō" glyphtame="Omacron" horizAdvX="528" d="M164 862H366V793H164V862ZM364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="ō" glyphtame="omacron" horizAdvX="463" d="M130 704H332V636H130V704ZM231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="Ő" glyphtame="Ohungarumlaut" horizAdvX="528" d="M304 893L217 769H148L220 893H304ZM432 893L340 769H271L348 893H432ZM364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="ő" glyphtame="ohungarumlaut" horizAdvX="463" d="M268 734L181 610H112L184 734H268ZM396 734L304 610H235L312 734H396ZM231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="Œ" glyphtame="OE" horizAdvX="765" d="M475 95H728V0H374V40Q324 -13 254 -13Q159 -13 105 49T50 222V478Q50 588 104 650T254 713Q324 713 374 660V700H717V605H475V402H699V309H475V95ZM260 86Q374 86 374 222V478Q374 614 260 614Q154 614 154 478V222Q154 86 260 86Z"/>
<glyph unicode="œ" glyphtame="oe" horizAdvX="715" d="M581 170H678Q674 84 625 37T493 -11Q408 -11 363 55Q315 -11 229 -11T95 41T46 189V338Q46 434 94 485T229 537Q317 537 362 472Q407 537 493 537Q578 537 626 486T674 342V229H411V187Q411 82 494 82Q536 82 556 103T581 170ZM411 343V301H579V343Q579 446 494 446Q411 446 411 343ZM315 189V338Q315 443 229 443Q144 443 144 338V189Q144 83 229 83Q315 83 315 189Z"/>
<glyph unicode="Ŕ" glyphtame="Racute" horizAdvX="504" d="M381 893L272 768H196L289 893H381ZM375 0L234 254H171V0H70V700H251Q351 700 406 643T461 481Q461 400 428 345T335 268L487 0H375ZM171 604V350H249Q302 350 329 383T357 479Q357 604 250 604H171Z"/>
<glyph unicode="ŕ" glyphtame="racute" horizAdvX="340" d="M292 734L203 610H127L202 734H292ZM273 539Q308 539 328 531V435Q300 447 263 447Q213 447 184 415T155 325V0H58V527H127L138 466Q181 539 273 539Z"/>
<glyph unicode="Ŗ" glyphtame="Rcommaaccent" horizAdvX="504" d="M335 268L487 0H375L234 254H171V0H70V700H251Q351 700 406 643T461 481Q461 400 428 345T335 268ZM171 604V350H249Q302 350 329 383T357 479Q357 604 250 604H171ZM197 -265L214 -70H302L259 -265H197Z"/>
<glyph unicode="ŗ" glyphtame="rcommaaccent" horizAdvX="340" d="M273 539Q308 539 328 531V435Q300 447 263 447Q213 447 184 415T155 325V0H58V527H127L138 466Q181 539 273 539ZM66 -265L83 -70H171L128 -265H66Z"/>
<glyph unicode="Ř" glyphtame="Rcaron" horizAdvX="504" d="M296 768H213L132 893H204L255 823L305 893H377L296 768ZM375 0L234 254H171V0H70V700H251Q351 700 406 643T461 481Q461 400 428 345T335 268L487 0H375ZM171 604V350H249Q302 350 329 383T357 479Q357 604 250 604H171Z"/>
<glyph unicode="ř" glyphtame="rcaron" horizAdvX="340" d="M227 609H144L63 734H135L186 664L236 734H308L227 609ZM273 539Q308 539 328 531V435Q300 447 263 447Q213 447 184 415T155 325V0H58V527H127L138 466Q181 539 273 539Z"/>
<glyph unicode="Ś" glyphtame="Sacute" horizAdvX="465" d="M174 768L267 893H359L250 768H174ZM231 -13Q137 -13 82 42T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 613 90 663T230 713Q319 713 371 660T425 517H322Q321 566 297 591T231 616T165 594T141 534Q141 497 171 467T245 412T331 360T404 286T435 180Q435 98 381 43T231 -13Z"/>
<glyph unicode="ś" glyphtame="sacute" horizAdvX="417" d="M315 736L226 612H150L225 736H315ZM214 -13Q127 -13 77 36T25 162H121Q122 124 145 101T212 77Q250 77 271 94T292 143Q292 168 273 186T225 214T163 240T100 271T52 319T33 393Q33 460 79 500T203 540Q285 540 330 494T378 372H283Q281 452 203 452Q169 452 149 436T129 392Q129 365 156 347T221 315T297 284T362 230T389 142Q389 72 342 30T214 -13Z"/>
<glyph unicode="Ş" glyphtame="Scedilla" horizAdvX="465" d="M435 180Q435 106 390 53T264 -11L253 -49Q256 -48 265 -48Q296 -48 316 -67T337 -119Q337 -159 309 -182T236 -206Q197 -206 169 -184T137 -124H197Q207 -151 237 -151Q276 -151 276 -117Q276 -85 240 -85Q221 -85 207 -93L188 -75L206 -12Q123 -4 75 50T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 613 90 663T230 713Q319 713 371 660T425 517H322Q321 566 297 591T231 616T165 594T141 534Q141 497 171 467T245 412T331 360T404 286T435 180Z"/>
<glyph unicode="ş" glyphtame="scedilla" horizAdvX="417" d="M389 142Q389 76 348 35T235 -12L224 -49Q227 -48 236 -48Q267 -48 287 -67T308 -119Q308 -159 280 -182T207 -206Q168 -206 140 -184T108 -124H168Q178 -151 208 -151Q247 -151 247 -117Q247 -85 211 -85Q192 -85 178 -93L159 -75L178 -10Q106 0 66 47T25 162H121Q122 124 145 101T212 77Q250 77 271 94T292 143Q292 168 273 186T225 214T163 240T100 271T52 319T33 393Q33 460 79 500T203 540Q285 540 330 494T378 372H283Q281 452 203 452Q169 452 149 436T129 392Q129 365 156 347T221 315T297 284T362 230T389 142Z"/>
<glyph unicode="Š" glyphtame="Scaron" horizAdvX="465" d="M274 768H191L110 893H182L233 823L283 893H355L274 768ZM141 534Q141 497 171 467T245 412T331 360T404 286T435 180Q435 98 381 43T231 -13Q137 -13 82 42T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 613 90 663T230 713Q319 713 371 660T425 517H322Q321 566 297 591T231 616T165 594T141 534Z"/>
<glyph unicode="š" glyphtame="scaron" horizAdvX="417" d="M250 611H167L86 736H158L209 666L259 736H331L250 611ZM129 392Q129 365 156 347T221 315T297 284T362 230T389 142Q389 72 342 30T214 -13Q127 -13 77 36T25 162H121Q122 124 145 101T212 77Q250 77 271 94T292 143Q292 168 273 186T225 214T163 240T100 271T52 319T33 393Q33 460 79 500T203 540Q285 540 330 494T378 372H283Q281 452 203 452Q169 452 149 436T129 392Z"/>
<glyph unicode="Ţ" glyphtame="uni0162" horizAdvX="427" d="M411 700V604H265V0H162V604H17V700H411ZM157 -265L174 -70H262L219 -265H157Z"/>
<glyph unicode="ţ" glyphtame="uni0163" horizAdvX="306" d="M259 85H291V-6H254Q176 -6 132 41T87 170V439H13V527H87V652H183V527H281V439H183V169Q183 85 259 85ZM121 -265L138 -70H226L183 -265H121Z"/>
<glyph unicode="Ť" glyphtame="Tcaron" horizAdvX="427" d="M256 768H173L92 893H164L215 823L265 893H337L256 768ZM411 700V604H265V0H162V604H17V700H411Z"/>
<glyph unicode="ť" glyphtame="tcaron" horizAdvX="365" d="M253 771H340L299 591H238L253 771ZM183 169Q183 85 259 85H291V-6H254Q176 -6 132 41T87 170V439H13V527H87V652H183V527H281V439H183V169Z"/>
<glyph unicode="Ŧ" glyphtame="Tbar" horizAdvX="427" d="M411 604H265V385H346V325H265V0H162V325H82V385H162V604H17V700H411V604Z"/>
<glyph unicode="ŧ" glyphtame="tbar" horizAdvX="316" d="M259 85H291V-6H254Q176 -6 132 41T87 170V252H30V318H87V439H13V527H87V652H183V527H281V439H183V318H255V252H183V169Q183 85 259 85Z"/>
<glyph unicode="Ū" glyphtame="Umacron" horizAdvX="530" d="M166 862H368V793H166V862ZM265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="ū" glyphtame="umacron" horizAdvX="469" d="M333 702V634H131V702H333ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="Ů" glyphtame="Uring" horizAdvX="530" d="M186 808T186 843T208 899T266 920Q303 920 325 899T347 843T325 786T266 764Q230 764 208 786ZM234 856T234 842T243 819T266 810T289 819T298 842T289 865T266 874T243 865ZM265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="ů" glyphtame="uring" horizAdvX="469" d="M232 604Q196 604 174 626T152 683T174 739T232 760Q269 760 291 739T313 683T291 626T232 604ZM246 714T232 714T209 705T200 682T209 659T232 650T255 659T264 682T255 705ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="Ű" glyphtame="Uhungarumlaut" horizAdvX="530" d="M306 893L219 769H150L222 893H306ZM434 893L342 769H273L350 893H434ZM265 -10Q169 -10 114 49T58 216V700H162V216Q162 88 265 88Q313 88 340 120T367 216V700H471V216Q471 109 416 50T265 -10Z"/>
<glyph unicode="ű" glyphtame="uhungarumlaut" horizAdvX="469" d="M269 732L182 608H113L185 732H269ZM305 608H236L313 732H397L305 608ZM314 527H411V0H342L332 59Q289 -13 201 -13Q131 -13 92 39T53 196V527H150V201Q150 137 168 108T224 79Q266 79 290 111T314 201V527Z"/>
<glyph unicode="Ų" glyphtame="Uogonek" horizAdvX="527" d="M323 -193Q278 -193 254 -170T230 -110Q230 -53 292 -1L291 0Q276 -10 259 -10Q167 -8 113 50T58 211V700H162V211Q162 151 189 120T263 88Q311 88 337 119T364 211V700H468V211Q468 98 398 35Q320 -39 320 -83Q320 -116 355 -116Q372 -116 385 -113V-184Q359 -193 323 -193Z"/>
<glyph unicode="ų" glyphtame="uogonek" horizAdvX="469" d="M411 0Q383 -19 366 -43T349 -87Q349 -116 384 -116Q398 -116 410 -113V-184Q389 -193 350 -193Q309 -193 287 -170T264 -111Q264 -50 342 0L332 60Q289 -12 202 -12Q53 -12 53 197V527H150V201Q150 138 168 109T224 80Q266 80 290 112T314 201V527H411V0Z"/>
<glyph unicode="Ŵ" glyphtame="Wcircumflex" horizAdvX="751" d="M381 838L330 768H258L339 893H422L503 768H431L381 838ZM639 700H744L587 0H479L411 309Q386 419 378 534H374Q363 411 340 309L272 0H164L7 700H112L184 317Q213 152 216 131H220Q228 187 252 322L322 689H429L499 322Q523 187 531 131H535Q538 152 567 317L639 700Z"/>
<glyph unicode="ŵ" glyphtame="wcircumflex" horizAdvX="636" d="M315 677L264 607H192L273 732H356L437 607H365L315 677ZM532 527H632L507 0H402L356 191Q328 315 320 399H316Q309 315 280 191L235 0H130L4 527H105L152 291Q171 189 182 110H186Q198 197 219 295L267 517H370L418 295Q443 165 451 110H455Q461 160 485 291L532 527Z"/>
<glyph unicode="Ŷ" glyphtame="Ycircumflex" horizAdvX="446" d="M224 838L173 768H101L182 893H265L346 768H274L224 838ZM338 700H444L274 230V0H173V230L3 700H108L187 467Q212 392 222 357H224L260 469L338 700Z"/>
<glyph unicode="ŷ" glyphtame="ycircumflex" horizAdvX="417" d="M206 681L155 611H83L164 736H247L328 611H256L206 681ZM313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="Ÿ" glyphtame="Ydieresis" horizAdvX="446" d="M173 782T150 782T114 795T100 831Q100 854 113 867T150 881T186 868T200 831Q200 809 187 796ZM319 782T296 782T259 795T245 831Q245 854 259 867T296 881T332 868T346 831Q346 809 333 796ZM173 0V230L3 700H108L187 467Q212 392 222 357H224L260 469L338 700H444L274 230V0H173Z"/>
<glyph unicode="Ź" glyphtame="Zacute" horizAdvX="421" d="M151 768L244 893H336L227 768H151ZM140 96H396V0H17V70L267 604H32V700H391V631L140 96Z"/>
<glyph unicode="ź" glyphtame="zacute" horizAdvX="366" d="M284 734L195 610H119L194 734H284ZM132 89H348V0H10V65L217 438H26V527H339V463L132 89Z"/>
<glyph unicode="Ż" glyphtame="Zdotaccent" horizAdvX="421" d="M235 788T208 788T164 805T147 849Q147 876 164 893T208 911T252 894T270 849Q270 823 253 806ZM140 96H396V0H17V70L267 604H32V700H391V631L140 96Z"/>
<glyph unicode="ż" glyphtame="zdotaccent" horizAdvX="366" d="M204 615T177 615T133 632T116 676Q116 703 133 720T177 738T221 721T239 676Q239 650 222 633ZM132 89H348V0H10V65L217 438H26V527H339V463L132 89Z"/>
<glyph unicode="Ž" glyphtame="Zcaron" horizAdvX="421" d="M251 768H168L87 893H159L210 823L260 893H332L251 768ZM140 96H396V0H17V70L267 604H32V700H391V631L140 96Z"/>
<glyph unicode="ž" glyphtame="zcaron" horizAdvX="366" d="M219 609H136L55 734H127L178 664L228 734H300L219 609ZM132 89H348V0H10V65L217 438H26V527H339V463L132 89Z"/>
<glyph unicode="Ș" glyphtame="Scommaaccent" horizAdvX="465" d="M231 -13Q137 -13 82 42T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 613 90 663T230 713Q319 713 371 660T425 517H322Q321 566 297 591T231 616T165 594T141 534Q141 497 171 467T245 412T331 360T404 286T435 180Q435 98 381 43T231 -13ZM182 -265L199 -70H287L244 -265H182Z"/>
<glyph unicode="ș" glyphtame="scommaaccent" horizAdvX="417" d="M214 -13Q127 -13 77 36T25 162H121Q122 124 145 101T212 77Q250 77 271 94T292 143Q292 168 273 186T225 214T163 240T100 271T52 319T33 393Q33 460 79 500T203 540Q285 540 330 494T378 372H283Q281 452 203 452Q169 452 149 436T129 392Q129 365 156 347T221 315T297 284T362 230T389 142Q389 72 342 30T214 -13ZM159 -265L176 -70H264L221 -265H159Z"/>
<glyph unicode="Ț" glyphtame="uni021A" horizAdvX="427" d="M411 700V604H265V0H162V604H17V700H411ZM157 -265L174 -70H262L219 -265H157Z"/>
<glyph unicode="ț" glyphtame="uni021B" horizAdvX="306" d="M259 85H291V-6H254Q176 -6 132 41T87 170V439H13V527H87V652H183V527H281V439H183V169Q183 85 259 85ZM121 -265L138 -70H226L183 -265H121Z"/>
<glyph unicode="̀" glyphtame="gravecomb" horizAdvX="0" d="M-165 734H-75L0 610H-76L-165 734Z"/>
<glyph unicode="́" glyphtame="acutecomb" horizAdvX="0" d="M0 734L-89 610H-165L-90 734H0Z"/>
<glyph unicode="̂" glyphtame="uni0302" horizAdvX="0" d="M1 609H-71L-121 679L-172 609H-244L-163 734H-80L1 609Z"/>
<glyph unicode="̃" glyphtame="tildecomb" horizAdvX="0" d="M-157 653Q-176 653 -181 626H-246Q-244 669 -223 693T-167 718Q-144 718 -121 703T-89 688Q-68 688 -63 715H0Q-4 672 -25 648T-79 623Q-102 623 -125 638T-157 653Z"/>
<glyph unicode="̄" glyphtame="uni0304" horizAdvX="0" d="M-202 704H0V636H-202V704Z"/>
<glyph unicode="̆" glyphtame="uni0306" horizAdvX="0" d="M-68 609T-121 609T-207 641T-242 729H-173Q-170 676 -122 676Q-73 676 -70 729H-1Q-3 672 -35 641Z"/>
<glyph unicode="̇" glyphtame="uni0307" horizAdvX="0" d="M-71 615T-98 615T-142 632T-159 676Q-159 703 -142 720T-98 738T-54 721T-36 676Q-36 650 -53 633Z"/>
<glyph unicode="̈" glyphtame="uni0308" horizAdvX="0" d="M-168 620T-190 620T-226 634T-240 670T-226 706T-190 720T-154 706T-140 670T-154 634ZM-50 620Q-73 620 -87 633T-101 670T-87 706T-50 720Q-28 720 -14 706T0 670T-14 634T-50 620Z"/>
<glyph unicode="̊" glyphtame="uni030A" horizAdvX="0" d="M-169 650T-169 685T-147 741T-89 762Q-52 762 -30 741T-8 685T-30 628T-89 606Q-125 606 -147 628ZM-121 698T-121 684T-112 661T-89 652T-66 661T-57 684T-66 707T-89 716T-112 707Z"/>
<glyph unicode="̋" glyphtame="uni030B" horizAdvX="0" d="M-128 734L-215 610H-284L-212 734H-128ZM0 734L-92 610H-161L-84 734H0Z"/>
<glyph unicode="̌" glyphtame="uni030C" horizAdvX="0" d="M-67 734H5L-76 609H-159L-240 734H-168L-117 664L-67 734Z"/>
<glyph unicode="̒" glyphtame="uni0312" horizAdvX="0" d="M-51 800L0 604H-96L-114 800H-51Z"/>
<glyph unicode="̦" glyphtame="uni0326" horizAdvX="0" d="M-106 -265L-89 -70H-1L-44 -265H-106Z"/>
<glyph unicode="̧" glyphtame="uni0327" horizAdvX="0" d="M-110 -206Q-149 -206 -177 -184T-209 -124H-149Q-139 -151 -109 -151Q-70 -151 -70 -117Q-70 -85 -106 -85Q-125 -85 -139 -93L-158 -75L-136 0H-79L-93 -49Q-90 -48 -81 -48Q-50 -48 -30 -67T-9 -119Q-9 -159 -37 -182T-110 -206Z"/>
<glyph unicode="̨" glyphtame="uni0328" horizAdvX="0" d="M-5 -184Q-28 -193 -67 -193Q-112 -193 -136 -170T-160 -110Q-160 -52 -100 0H-15Q-39 -18 -54 -42T-70 -85Q-70 -116 -35 -116Q-18 -116 -5 -113V-184Z"/>
<glyph unicode="̵" glyphtame="uni0335" horizAdvX="0" d="M-210 372V428H-10V372H-210Z"/>
<glyph unicode="̶" glyphtame="uni0336" horizAdvX="0" d="M-330 428H-10V372H-330V428Z"/>
<glyph unicode="̷" glyphtame="uni0337" horizAdvX="0" d="M-269 -44L-110 552H-7L-166 -44H-269Z"/>
<glyph unicode="̸" glyphtame="uni0338" horizAdvX="0" d="M-350 -38L-137 738H-36L-249 -38H-350Z"/>
<glyph unicode="Ё" glyphtame="uni0401" horizAdvX="461" d="M199 782T176 782T140 795T126 831Q126 854 139 867T176 881T212 868T226 831Q226 809 213 796ZM345 782T322 782T285 795T271 831Q271 854 285 867T322 881T358 868T372 831Q372 809 359 796ZM171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="Ђ" glyphtame="uni0402" horizAdvX="566" d="M375 519Q520 519 520 300V185Q519 93 472 44T334 -6V87Q423 88 423 186V298Q423 367 405 397T349 427Q309 427 285 397T260 313V0H157V604H17V700H401V604H260V456Q301 519 375 519Z"/>
<glyph unicode="Ѓ" glyphtame="uni0403" horizAdvX="422" d="M353 893L244 768H168L261 893H353ZM405 700V604H171V0H70V700H405Z"/>
<glyph unicode="Є" glyphtame="uni0404" horizAdvX="492" d="M364 202H464Q459 99 405 44T253 -12Q159 -12 105 45T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V399H332V312H153V206Q153 145 179 115T253 84Q354 84 364 202Z"/>
<glyph unicode="Ѕ" glyphtame="uni0405" horizAdvX="465" d="M231 -13Q137 -13 82 42T26 192H129Q130 139 156 112T231 85Q280 85 305 111T331 181Q331 209 315 233T273 276T216 313T154 353T96 398T54 458T38 536Q38 613 90 663T230 713Q319 713 371 660T425 517H322Q321 566 297 591T231 616T165 594T141 534Q141 497 171 467T245 412T331 360T404 286T435 180Q435 98 381 43T231 -13Z"/>
<glyph unicode="І" glyphtame="uni0406" horizAdvX="240" d="M70 700H171V0H70V700Z"/>
<glyph unicode="Ї" glyphtame="uni0407" horizAdvX="240" d="M72 782T49 782T13 795T-1 831Q-1 854 12 867T49 881T85 868T99 831Q99 809 86 796ZM218 782T195 782T158 795T144 831Q144 854 158 867T195 881T231 868T245 831Q245 809 232 796ZM70 700H171V0H70V700Z"/>
<glyph unicode="Ј" glyphtame="uni0408" horizAdvX="284" d="M0 -116V-17Q59 -17 87 12T116 112V700H217V112Q217 1 162 -57T0 -116Z"/>
<glyph unicode="Љ" glyphtame="uni0409" horizAdvX="752" d="M0 -10V83Q34 83 52 106T78 183Q91 264 108 700H448V420H521Q623 420 677 364T732 207Q732 106 678 53T521 0H345V605H203Q188 254 173 158Q158 65 117 28T0 -10ZM448 94H515Q628 94 628 210Q628 324 515 324H448V94Z"/>
<glyph unicode="Њ" glyphtame="uni040A" horizAdvX="781" d="M548 414Q651 414 706 359T761 208Q761 106 707 53T548 0H365V317H171V0H70V700H171V412H365V700H466V414H548ZM543 94Q656 94 656 208Q656 262 627 290T543 318H466V94H543Z"/>
<glyph unicode="Ћ" glyphtame="uni040B" horizAdvX="573" d="M375 519Q520 519 520 300V0H423V298Q423 367 405 397T349 427Q310 427 286 398T260 314V0H157V604H17V700H401V604H260V456Q301 519 375 519Z"/>
<glyph unicode="Ќ" glyphtame="uni040C" horizAdvX="503" d="M352 893L243 768H167L260 893H352ZM387 0L216 306L170 237V0H70V700H170V396L216 478L356 700H474L274 395L503 0H387Z"/>
<glyph unicode="Ѝ" glyphtame="uni040D" horizAdvX="554" d="M342 768H266L157 893H249L342 768ZM70 0V700H169V394Q169 288 158 192L161 190Q196 282 211 314L381 700H484V0H385V305Q385 406 394 510L391 512Q361 429 341 383L172 0H70Z"/>
<glyph unicode="Ў" glyphtame="uni040E" horizAdvX="436" d="M274 768T221 768T135 800T100 888H169Q172 835 220 835Q269 835 272 888H341Q339 831 307 800ZM327 700H433L263 147Q236 60 194 25T72 -10H47V81H75Q115 81 136 103T173 179L181 205L5 700H112L226 359L327 700Z"/>
<glyph unicode="Џ" glyphtame="uni040F" horizAdvX="539" d="M220 -117V0H70V700H171V96H369V700H470V0H319V-117H220Z"/>
<glyph unicode="А" glyphtame="uni0410" horizAdvX="476" d="M363 0L330 141H145L113 0H7L183 700H292L468 0H363ZM167 235H309L265 423Q250 493 239 573H236Q223 478 210 423L167 235Z"/>
<glyph unicode="Б" glyphtame="uni0411" horizAdvX="502" d="M262 424Q361 424 416 367T472 213Q472 109 418 55T262 0H70V700H434V605H171V424H262ZM256 96Q309 96 339 125T369 213Q369 270 339 300T256 330H171V96H256Z"/>
<glyph unicode="В" glyphtame="uni0412" horizAdvX="506" d="M375 360Q420 341 446 299T472 200Q472 107 417 54T270 0H70V700H260Q345 700 395 649T446 510Q446 405 375 360ZM248 605H171V404H251Q343 404 343 507Q343 605 248 605ZM257 95Q369 95 369 200Q369 253 338 281T253 309H171V95H257Z"/>
<glyph unicode="Г" glyphtame="uni0413" horizAdvX="422" d="M405 700V604H171V0H70V700H405Z"/>
<glyph unicode="Д" glyphtame="uni0414" horizAdvX="556" d="M475 95H537V-117H438V0H105V-117H6V95H47Q119 168 126 362L138 700H475V95ZM375 95V605H233L223 334Q217 182 168 95H375Z"/>
<glyph unicode="Е" glyphtame="uni0415" horizAdvX="461" d="M171 95H423V0H70V700H413V605H171V402H394V309H171V95Z"/>
<glyph unicode="Ж" glyphtame="uni0416" horizAdvX="724" d="M610 0L445 297L411 244V0H313V244L279 297L114 0H0L223 382L17 700H133L265 484L313 397V700H411V396L462 487L591 700H707L501 382L724 0H610Z"/>
<glyph unicode="З" glyphtame="uni0417" horizAdvX="487" d="M335 363Q456 329 456 194Q456 99 396 43T240 -13Q143 -13 85 47T21 200H123Q126 150 156 117T239 84Q290 84 322 114T354 199Q354 255 320 285T229 315H176V405H220Q336 405 336 515Q336 563 311 590T239 617Q140 617 133 499H33Q38 598 91 656T239 714Q330 714 384 661T438 514Q438 401 335 363Z"/>
<glyph unicode="И" glyphtame="uni0418" horizAdvX="554" d="M70 0V700H169V394Q169 288 158 192L161 190Q196 282 211 314L381 700H484V0H385V305Q385 406 394 510L391 512Q361 429 341 383L172 0H70Z"/>
<glyph unicode="Й" glyphtame="uni0419" horizAdvX="554" d="M333 768T280 768T194 800T159 888H228Q231 835 279 835Q328 835 331 888H400Q398 831 366 800ZM70 0V700H169V394Q169 288 158 192L161 190Q196 282 211 314L381 700H484V0H385V305Q385 406 394 510L391 512Q361 429 341 383L172 0H70Z"/>
<glyph unicode="К" glyphtame="uni041A" horizAdvX="503" d="M387 0L216 306L170 237V0H70V700H170V396L216 478L356 700H474L274 395L503 0H387Z"/>
<glyph unicode="Л" glyphtame="uni041B" horizAdvX="525" d="M109 700H455V0H354V605H206Q189 252 174 158Q161 66 119 28T0 -10V82Q33 82 51 104T77 181Q91 277 109 700Z"/>
<glyph unicode="М" glyphtame="uni041C" horizAdvX="688" d="M515 700H618V0H519V273Q523 420 527 499L523 501Q503 445 455 317L394 158H294L233 317Q185 445 165 501L161 499Q167 353 169 273V0H70V700H173L291 416Q314 359 342 277H346Q374 359 397 416L515 700Z"/>
<glyph unicode="Н" glyphtame="uni041D" horizAdvX="540" d="M369 700H470V0H369V307H171V0H70V700H171V402H369V700Z"/>
<glyph unicode="О" glyphtame="uni041E" horizAdvX="528" d="M364 -13T264 -13T107 49T50 222V478Q50 588 107 650T264 713T421 651T478 478V222Q478 112 421 50ZM264 86Q318 86 346 121T374 222V478Q374 544 346 579T264 614Q154 614 154 478V222Q154 86 264 86Z"/>
<glyph unicode="П" glyphtame="uni041F" horizAdvX="539" d="M470 700V0H369V604H171V0H70V700H470Z"/>
<glyph unicode="Р" glyphtame="uni0420" horizAdvX="501" d="M255 700Q358 700 414 641T470 474Q470 364 414 305T255 246H171V0H70V700H255ZM254 342Q367 342 367 474Q367 604 254 604H171V342H254Z"/>
<glyph unicode="С" glyphtame="uni0421" horizAdvX="492" d="M253 -12Q159 -12 105 45T50 206V494Q50 599 104 656T252 713Q348 713 402 658T460 498H360Q351 617 251 617Q205 617 179 586T153 494V206Q153 145 179 115T253 84Q354 84 364 202H464Q459 99 405 44T253 -12Z"/>
<glyph unicode="Т" glyphtame="uni0422" horizAdvX="427" d="M411 700V604H265V0H162V604H17V700H411Z"/>
<glyph unicode="У" glyphtame="uni0423" horizAdvX="436" d="M327 700H433L263 147Q236 60 194 25T72 -10H47V81H75Q115 81 136 103T173 179L181 205L5 700H112L226 359L327 700Z"/>
<glyph unicode="Ф" glyphtame="uni0424" horizAdvX="668" d="M284 -62V16H268Q160 16 94 83T28 272V425Q28 546 94 615T268 684H284V758H384V684H400Q508 684 574 615T640 425V272Q640 151 574 84T400 16H384V-62H284ZM264 113H284V587H264Q129 587 129 425V272Q129 192 164 153T264 113ZM384 113H403Q539 113 539 272V425Q539 587 403 587H384V113Z"/>
<glyph unicode="Х" glyphtame="uni0425" horizAdvX="476" d="M461 0H349L239 259L128 0H16L183 363L26 700H137L239 463L340 700H451L294 363L461 0Z"/>
<glyph unicode="Ц" glyphtame="uni0426" horizAdvX="549" d="M431 -117V0H70V700H171V96H365V700H466V96H530V-117H431Z"/>
<glyph unicode="Ч" glyphtame="uni0427" horizAdvX="509" d="M338 700H439V-2H338V257Q294 241 243 241Q147 241 92 299T37 464V700H138V461Q138 336 254 336Q300 336 338 351V700Z"/>
<glyph unicode="Ш" glyphtame="uni0428" horizAdvX="805" d="M70 0V700H170V96H352V700H452V96H635V700H735V0H70Z"/>
<glyph unicode="Щ" glyphtame="uni0429" horizAdvX="815" d="M733 96H795V-117H696V0H70V700H170V96H351V700H451V96H633V700H733V96Z"/>
<glyph unicode="Ъ" glyphtame="uni042A" horizAdvX="557" d="M143 0V607H15V700H245V422H324Q427 422 482 365T537 211Q537 107 483 54T324 0H143ZM245 94H318Q434 94 434 211Q434 326 318 326H245V94Z"/>
<glyph unicode="Ы" glyphtame="uni042B" horizAdvX="709" d="M70 0V700H173V420H244Q346 420 401 364T456 210Q456 107 402 54T244 0H70ZM539 0V700H640V0H539ZM173 94H239Q351 94 351 210Q351 266 322 295T239 324H173V94Z"/>
<glyph unicode="Ь" glyphtame="uni042C" horizAdvX="487" d="M70 0V700H171V421H257Q357 421 412 364T467 210Q467 107 413 54T257 0H70ZM171 93H252Q305 93 334 122T363 210Q363 267 334 296T252 325H171V93Z"/>
<glyph unicode="Э" glyphtame="uni042D" horizAdvX="492" d="M240 713Q334 713 388 656T442 494V206Q442 102 388 45T239 -12Q142 -12 88 43T28 202H128Q138 84 239 84Q286 84 312 114T339 206V312H160V399H339V494Q339 555 313 586T241 617Q141 617 132 498H32Q36 602 90 657T240 713Z"/>
<glyph unicode="Ю" glyphtame="uni042E" horizAdvX="756" d="M394 710T494 710T649 647T705 471V229Q705 116 650 53T494 -10T339 53T283 229V307H170V0H70V700H170V402H283V471Q283 584 338 647ZM602 229V471Q602 612 494 612Q387 612 387 471V229Q387 88 494 88Q602 88 602 229Z"/>
<glyph unicode="Я" glyphtame="uni042F" horizAdvX="502" d="M17 0L168 270Q108 290 76 343T43 475Q43 582 98 641T252 700H432V0H331V253H269L129 0H17ZM252 348H331V604H253Q146 604 146 477Q146 415 173 382T252 348Z"/>
<glyph unicode="а" glyphtame="uni0430" horizAdvX="454" d="M225 540Q309 540 352 491T396 350V0H317L310 49Q273 -13 193 -13Q120 -13 76 31T31 150Q31 229 82 271T222 313H299V350Q299 447 223 447Q190 447 169 429T143 377H46Q50 453 98 496T225 540ZM299 157V229H222Q129 229 129 150Q129 114 151 94T213 73Q252 73 275 96T299 157Z"/>
<glyph unicode="б" glyphtame="uni0431" horizAdvX="471" d="M261 490Q335 490 380 438T426 293V188Q426 93 377 41T241 -11Q156 -11 107 41T58 188V467Q58 558 104 606T229 682Q272 696 290 707T326 746L396 689Q361 624 273 598Q247 591 231 584T194 564T164 528T154 473V446Q193 490 261 490ZM327 188V287Q327 396 243 396Q204 396 180 371T156 291V188Q156 83 241 83Q327 83 327 188Z"/>
<glyph unicode="в" glyphtame="uni0432" horizAdvX="430" d="M323 280Q365 264 386 230T408 153Q408 80 362 40T224 0H58V527H216Q295 527 338 489T381 381Q381 318 323 280ZM152 446V311H211Q286 311 286 379Q286 410 267 428T210 446H152ZM228 82Q312 82 312 156Q312 229 228 229H152V82H228Z"/>
<glyph unicode="г" glyphtame="uni0433" horizAdvX="346" d="M332 528V435H156V0H58V528H332Z"/>
<glyph unicode="д" glyphtame="uni0434" horizAdvX="476" d="M0 -117V89H40Q78 144 87 274Q95 413 98 527H404V89H464V-117H371V0H93V-117H0ZM150 89H308V438H189Q187 330 181 252Q173 150 150 89Z"/>
<glyph unicode="е" glyphtame="uni0435" horizAdvX="454" d="M320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="ж" glyphtame="uni0436" horizAdvX="632" d="M526 0L393 226L363 192V0H270V191L239 226L106 0H-3L179 301L16 527H129L207 407Q252 335 270 305V527H363V305Q381 335 426 407L504 527H617L454 301L635 0H526Z"/>
<glyph unicode="з" glyphtame="uni0437" horizAdvX="419" d="M314 278Q347 263 369 231T392 156Q392 79 342 34T207 -12Q122 -12 72 33T18 154H111Q114 119 139 99T207 78Q249 78 273 99T297 161Q297 195 274 214T208 233H141V308H205Q241 308 261 326T282 379Q282 411 262 430T206 449Q128 449 122 368H28Q33 449 78 493T204 537Q282 537 329 495T376 386Q376 312 314 278Z"/>
<glyph unicode="и" glyphtame="uni0438" horizAdvX="467" d="M58 0V527H152V305Q152 216 150 159L153 157Q165 196 208 306L310 527H409V0H315V202Q315 284 320 372L317 373Q313 355 293 299T258 211L157 0H58Z"/>
<glyph unicode="й" glyphtame="uni0439" horizAdvX="467" d="M288 609T235 609T149 641T114 729H183Q186 676 234 676Q283 676 286 729H355Q353 672 321 641ZM58 0V527H152V305Q152 216 150 159L153 157Q165 196 208 306L310 527H409V0H315V202Q315 284 320 372L317 373Q313 355 293 299T258 211L157 0H58Z"/>
<glyph unicode="к" glyphtame="uni043A" horizAdvX="441" d="M332 0L189 226L154 188V0H58V527H154V303L216 400L303 527H418L252 302L443 0H332Z"/>
<glyph unicode="л" glyphtame="uni043B" horizAdvX="452" d="M0 -7V86Q37 86 51 120T72 227Q84 381 87 527H394V0H298V438H180Q178 325 167 211Q157 100 117 47T0 -7Z"/>
<glyph unicode="м" glyphtame="uni043C" horizAdvX="556" d="M400 527H497V0H407V209Q407 226 413 364L408 365Q384 285 365 233L322 131H233L190 233Q184 250 177 272T161 321T147 365L142 364Q148 226 148 209V0H58V527H155L236 337Q265 267 276 227H279Q287 253 319 337L400 527Z"/>
<glyph unicode="н" glyphtame="uni043D" horizAdvX="457" d="M302 527H399V0H302V225H155V0H58V527H155V315H302V527Z"/>
<glyph unicode="о" glyphtame="uni043E" horizAdvX="463" d="M231 -12Q145 -12 96 40T46 188V340Q46 436 95 488T231 540Q318 540 367 488T417 340V188Q417 92 368 40T231 -12ZM231 82Q319 82 319 188V340Q319 446 231 446Q144 446 144 340V188Q144 82 231 82Z"/>
<glyph unicode="п" glyphtame="uni043F" horizAdvX="457" d="M399 527V0H302V438H155V0H58V527H399Z"/>
<glyph unicode="р" glyphtame="uni0440" horizAdvX="467" d="M254 539Q330 539 376 485T422 335V190Q422 95 377 42T254 -12Q193 -12 153 30V-180H58V528H128L135 479Q155 505 187 522T254 539ZM323 190V335Q323 448 238 448Q199 448 176 420T153 336V190Q153 138 175 109T238 79Q323 79 323 190Z"/>
<glyph unicode="с" glyphtame="uni0441" horizAdvX="437" d="M224 -11Q141 -11 94 39T46 182V348Q46 439 93 489T224 540Q309 540 356 490T407 348H309Q306 447 225 447T144 348V182Q144 82 225 82Q308 82 312 182H409Q407 90 358 40T224 -11Z"/>
<glyph unicode="т" glyphtame="uni0442" horizAdvX="356" d="M347 527V436H226V0H130V436H10V527H347Z"/>
<glyph unicode="у" glyphtame="uni0443" horizAdvX="417" d="M313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="ф" glyphtame="uni0444" horizAdvX="623" d="M379 535Q469 535 523 481T577 329V202Q577 104 523 49T379 -7H358V-180H264V-7H244Q155 -7 101 48T46 202V329Q46 426 100 480T244 535H264V722H358V535H379ZM244 88H264V441H244Q198 441 171 413T144 329V202Q144 146 171 117T244 88ZM479 202V329Q479 385 452 413T379 441H358V88H379Q424 88 451 117T479 202Z"/>
<glyph unicode="х" glyphtame="uni0445" horizAdvX="407" d="M408 0H298L204 187L109 0H-1L145 264L10 527H117L204 348L290 527H397L264 264L408 0Z"/>
<glyph unicode="ц" glyphtame="uni0446" horizAdvX="474" d="M363 -117V0H58V527H155V89H300V527H397V89H457V-117H363Z"/>
<glyph unicode="ч" glyphtame="uni0447" horizAdvX="432" d="M282 527H374V0H282V184Q245 165 193 165Q119 165 74 218T29 378V527H121V378Q121 256 202 256Q253 256 282 275V527Z"/>
<glyph unicode="ш" glyphtame="uni0448" horizAdvX="682" d="M528 527H623V0H53V527H148V90H290V527H386V90H528V527Z"/>
<glyph unicode="щ" glyphtame="uni0449" horizAdvX="705" d="M629 88H688V-117H596V0H58V527H154V90H296V527H392V90H533V527H629V88Z"/>
<glyph unicode="ъ" glyphtame="uni044A" horizAdvX="480" d="M294 349Q370 349 419 301T468 176Q468 98 419 49T294 0H115V438H10V527H212V349H294ZM285 88Q324 88 347 112T371 176Q371 215 348 238T285 261H212V88H285Z"/>
<glyph unicode="ы" glyphtame="uni044B" horizAdvX="632" d="M238 344Q314 344 363 297T412 173Q412 96 363 48T238 0H58V525H155V344H238ZM476 525H573V0H476V525ZM228 88Q267 88 290 111T314 174Q314 212 291 234T228 256H155V88H228Z"/>
<glyph unicode="ь" glyphtame="uni044C" horizAdvX="424" d="M238 349Q314 349 363 301T412 176Q412 98 363 49T238 0H58V527H155V349H238ZM228 88Q267 88 290 112T314 176Q314 215 291 238T228 261H155V88H228Z"/>
<glyph unicode="э" glyphtame="uni044D" horizAdvX="437" d="M213 540Q296 540 343 490T391 348V182Q391 90 344 40T213 -11Q128 -11 79 38T28 177H125Q129 82 212 82Q293 82 293 182V232H135V301H293V348Q293 447 212 447T128 352H30Q33 442 80 491T213 540Z"/>
<glyph unicode="ю" glyphtame="uni044E" horizAdvX="634" d="M325 540T409 540T541 488T589 341V189Q589 93 541 41T409 -11T277 41T229 189V223H152V0H58V527H152V314H229V341Q229 436 277 488ZM492 189V341Q492 446 409 446Q325 446 325 341V189Q325 83 409 83Q492 83 492 189Z"/>
<glyph unicode="я" glyphtame="uni044F" horizAdvX="418" d="M203 527H365V0H271V178H214L119 0H9L121 192Q78 209 52 251T26 355Q26 434 74 480T203 527ZM271 261V439H207Q165 439 143 417T121 352Q121 261 205 261H271Z"/>
<glyph unicode="ё" glyphtame="uni0451" horizAdvX="454" d="M174 620T151 620T115 634T101 670T114 706T151 720T187 706T201 670T188 634ZM314 620T291 620T254 633T240 670T254 706T291 720T327 706T341 670T328 634ZM320 165H417Q410 81 360 35T225 -11Q142 -11 94 41T46 187V344Q46 437 94 488T226 540Q312 540 361 489T410 344V227H143V187Q143 82 227 82Q270 82 291 101T320 165ZM227 449Q143 449 143 345V300H314V345Q314 449 227 449Z"/>
<glyph unicode="ђ" glyphtame="uni0452" horizAdvX="469" d="M271 540Q417 540 417 321V-13Q417 -101 369 -150T226 -200V-107Q320 -106 320 -17V318Q320 387 302 417T245 448Q204 448 180 416T155 326V0H59V609H-5V664H59V724H155V664H249V609H155V476Q198 540 271 540Z"/>
<glyph unicode="ѓ" glyphtame="uni0453" horizAdvX="346" d="M298 734L209 610H133L208 734H298ZM332 528V435H156V0H58V528H332Z"/>
<glyph unicode="є" glyphtame="uni0454" horizAdvX="437" d="M312 177H409Q407 88 358 39T224 -11Q141 -11 94 39T46 182V348Q46 439 93 489T224 540Q309 540 356 491T407 352H309Q306 447 225 447T144 348V301H302V232H144V182Q144 82 225 82Q308 82 312 177Z"/>
<glyph unicode="ѕ" glyphtame="uni0455" horizAdvX="417" d="M214 -13Q127 -13 77 36T25 162H121Q122 124 145 101T212 77Q250 77 271 94T292 143Q292 168 273 186T225 214T163 240T100 271T52 319T33 393Q33 460 79 500T203 540Q285 540 330 494T378 372H283Q281 452 203 452Q169 452 149 436T129 392Q129 365 156 347T221 315T297 284T362 230T389 142Q389 72 342 30T214 -13Z"/>
<glyph unicode="і" glyphtame="uni0456" horizAdvX="213" d="M133 615T106 615T62 632T45 676Q45 703 62 720T106 738T150 721T168 676Q168 650 151 633ZM58 0V527H155V0H58Z"/>
<glyph unicode="ї" glyphtame="uni0457" horizAdvX="232" d="M73 624T51 624T16 637T3 672T16 707T51 721T86 708T99 672T86 637ZM203 624T181 624T146 637T132 672T145 707T181 721T216 708T230 672T217 637ZM67 0V527H164V0H67Z"/>
<glyph unicode="ј" glyphtame="uni0458" horizAdvX="246" d="M127 614Q100 614 83 631T66 675T83 719T127 737Q153 737 170 720T188 675Q188 649 171 632T127 614ZM-15 -193V-100Q34 -99 56 -75T79 0V527H175V4Q175 -90 127 -141T-15 -193Z"/>
<glyph unicode="љ" glyphtame="uni0459" horizAdvX="662" d="M0 -7V86Q37 86 51 120T72 227Q84 381 87 527H393V344H476Q552 344 601 297T650 173Q650 96 601 48T476 0H298V438H180Q178 325 167 211Q157 100 117 47T0 -7ZM393 88H467Q506 88 529 111T553 174Q553 212 530 234T467 256H393V88Z"/>
<glyph unicode="њ" glyphtame="uni045A" horizAdvX="666" d="M480 328Q556 328 605 284T654 168Q654 94 605 47T480 0H301V238H155V0H58V527H155V328H301V527H398V328H480ZM471 88Q509 88 532 109T556 168Q556 201 533 220T471 240H398V88H471Z"/>
<glyph unicode="ћ" glyphtame="uni045B" horizAdvX="467" d="M269 540Q415 540 415 321V0H318V318Q318 387 300 417T243 448Q202 448 178 416T153 326V0H57V605H-7V660H57V724H153V660H247V605H153V475Q195 540 269 540Z"/>
<glyph unicode="ќ" glyphtame="uni045C" horizAdvX="441" d="M327 734L238 610H162L237 734H327ZM332 0L189 226L154 188V0H58V527H154V303L216 400L303 527H418L252 302L443 0H332Z"/>
<glyph unicode="ѝ" glyphtame="uni045D" horizAdvX="467" d="M133 734H223L298 610H222L133 734ZM58 0V527H152V305Q152 216 150 159L153 157Q165 196 208 306L310 527H409V0H315V202Q315 284 320 372L317 373Q313 355 293 299T258 211L157 0H58Z"/>
<glyph unicode="ў" glyphtame="uni045E" horizAdvX="417" d="M261 611T208 611T122 643T87 731H156Q159 678 207 678Q256 678 259 731H328Q326 674 294 643ZM313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="џ" glyphtame="uni045F" horizAdvX="457" d="M399 527V0H275V-117H185V0H58V527H155V90H302V527H399Z"/>
<glyph unicode="Ґ" glyphtame="uni0490" horizAdvX="422" d="M320 815H405V604H171V0H70V700H320V815Z"/>
<glyph unicode="ґ" glyphtame="uni0491" horizAdvX="347" d="M251 627H333V435H156V0H58V527H251V627Z"/>
<glyph unicode="Ẁ" glyphtame="Wgrave" horizAdvX="751" d="M445 768H369L260 893H352L445 768ZM639 700H744L587 0H479L411 309Q386 419 378 534H374Q363 411 340 309L272 0H164L7 700H112L184 317Q213 152 216 131H220Q228 187 252 322L322 689H429L499 322Q523 187 531 131H535Q538 152 567 317L639 700Z"/>
<glyph unicode="ẁ" glyphtame="wgrave" horizAdvX="636" d="M380 608H304L215 732H305L380 608ZM532 527H632L507 0H402L356 191Q328 315 320 399H316Q309 315 280 191L235 0H130L4 527H105L152 291Q171 189 182 110H186Q198 197 219 295L267 517H370L418 295Q443 165 451 110H455Q461 160 485 291L532 527Z"/>
<glyph unicode="Ẃ" glyphtame="Wacute" horizAdvX="751" d="M322 768L415 893H507L398 768H322ZM639 700H744L587 0H479L411 309Q386 419 378 534H374Q363 411 340 309L272 0H164L7 700H112L184 317Q213 152 216 131H220Q228 187 252 322L322 689H429L499 322Q523 187 531 131H535Q538 152 567 317L639 700Z"/>
<glyph unicode="ẃ" glyphtame="wacute" horizAdvX="636" d="M421 732L332 608H256L331 732H421ZM532 527H632L507 0H402L356 191Q328 315 320 399H316Q309 315 280 191L235 0H130L4 527H105L152 291Q171 189 182 110H186Q198 197 219 295L267 517H370L418 295Q443 165 451 110H455Q461 160 485 291L532 527Z"/>
<glyph unicode="Ẅ" glyphtame="Wdieresis" horizAdvX="751" d="M330 782T307 782T271 795T257 831Q257 854 270 867T307 881T343 868T357 831Q357 809 344 796ZM476 782T453 782T416 795T402 831Q402 854 416 867T453 881T489 868T503 831Q503 809 490 796ZM639 700H744L587 0H479L411 309Q386 419 378 534H374Q363 411 340 309L272 0H164L7 700H112L184 317Q213 152 216 131H220Q228 187 252 322L322 689H429L499 322Q523 187 531 131H535Q538 152 567 317L639 700Z"/>
<glyph unicode="ẅ" glyphtame="wdieresis" horizAdvX="636" d="M268 620T245 620T209 634T195 670T208 706T245 720T281 706T295 670T282 634ZM408 620T385 620T348 633T334 670T348 706T385 720T421 706T435 670T422 634ZM532 527H632L507 0H402L356 191Q328 315 320 399H316Q309 315 280 191L235 0H130L4 527H105L152 291Q171 189 182 110H186Q198 197 219 295L267 517H370L418 295Q443 165 451 110H455Q461 160 485 291L532 527Z"/>
<glyph unicode="Ỳ" glyphtame="Ygrave" horizAdvX="446" d="M288 768H212L103 893H195L288 768ZM173 0V230L3 700H108L187 467Q212 392 222 357H224L260 469L338 700H444L274 230V0H173Z"/>
<glyph unicode="ỳ" glyphtame="ygrave" horizAdvX="417" d="M271 612H195L106 736H196L271 612ZM313 527H413L244 -41Q223 -113 191 -151T103 -190H51V-96H84Q112 -96 126 -79T151 -24L174 55L4 527H106L215 193L313 527Z"/>
<glyph unicode="–" glyphtame="endash" horizAdvX="421" d="M38 214V300H383V214H38Z"/>
<glyph unicode="—" glyphtame="emdash" horizAdvX="551" d="M38 300H513V214H38V300Z"/>
<glyph unicode="‘" glyphtame="quoteleft" horizAdvX="152" d="M112 465H16L73 700H133L112 465Z"/>
<glyph unicode="’" glyphtame="quoteright" horizAdvX="152" d="M40 704H136L79 469H19L40 704Z"/>
<glyph unicode="‚" glyphtame="quotesinglbase" horizAdvX="169" d="M47 98H143L86 -136H26L47 98Z"/>
<glyph unicode="“" glyphtame="quotedblleft" horizAdvX="282" d="M112 465H16L73 700H133L112 465ZM242 465H145L202 700H262L242 465Z"/>
<glyph unicode="”" glyphtame="quotedblright" horizAdvX="282" d="M40 700H136L79 465H19L40 700ZM169 700H265L209 465H149L169 700Z"/>
<glyph unicode="„" glyphtame="quotedblbase" horizAdvX="169" d="M47 98H143L86 -136H26L47 98Z"/>
<glyph unicode="†" glyphtame="dagger" horizAdvX="370" d="M347 520V433H231V-95H139V433H23V520H139V710H231V520H347Z"/>
<glyph unicode="‡" glyphtame="daggerdbl" horizAdvX="373" d="M348 433H232V205H348V117H232V-95H140V117H24V205H140V433H24V520H140V710H232V520H348V433Z"/>
<glyph unicode="•" glyphtame="bullet" horizAdvX="207" d="M103 169Q66 169 44 191T21 251Q21 287 43 309T103 332Q139 332 161 310T184 251Q184 214 162 192T103 169Z"/>
<glyph unicode="…" glyphtame="ellipsis" horizAdvX="543" d="M124 -10T97 -10T53 6T36 51Q36 78 53 94T97 111T141 95T158 51Q158 23 141 7ZM271 -10Q244 -10 228 6T211 51Q211 78 227 94T271 111Q299 111 315 95T332 51Q332 23 316 7T271 -10ZM473 -10T446 -10T402 6T385 51Q385 78 402 94T446 111T490 95T507 51Q507 23 490 7Z"/>
<glyph unicode="‰" glyphtame="perthousand" horizAdvX="840" d="M167 390T121 390T47 418T19 499V600Q19 652 47 681T121 710T195 681T223 600V499Q223 447 195 419ZM12 0L496 700H570L86 0H12ZM83 448T121 448T159 498V601Q159 652 121 652T83 601V498Q83 448 121 448ZM500 -9T454 -9T380 19T352 100V202Q352 254 380 283T454 312Q499 312 527 283T555 202V100Q555 48 528 20ZM755 -9T709 -9T635 19T607 100V202Q607 254 635 283T709 312Q754 312 782 283T810 202V100Q810 48 783 20ZM454 50Q491 50 491 99V203Q491 253 454 253Q416 253 416 203V99Q416 50 454 50ZM709 50Q746 50 746 99V203Q746 253 709 253Q671 253 671 203V99Q671 50 709 50Z"/>
<glyph unicode="‹" glyphtame="guilsinglleft" horizAdvX="289" d="M155 87L38 239V275L155 427H250L121 257L250 87H155Z"/>
<glyph unicode="›" glyphtame="guilsinglright" horizAdvX="289" d="M134 87H39L168 257L39 427H134L251 275V239L134 87Z"/>
<glyph unicode="⁄" glyphtame="fraction" horizAdvX="560" d="M11 0L466 700H549L93 0H11Z"/>
<glyph unicode="€" glyphtame="Euro" horizAdvX="555" d="M428 202H528Q523 99 468 44T317 -12Q223 -12 169 45T114 206V264H33V324H114V380H33V440H114V494Q114 599 167 656T315 713Q411 713 465 658T523 498H423Q416 617 315 617Q269 617 243 586T217 494V440H360V380H217V324H360V264H217V206Q217 145 243 115T317 84Q416 84 428 202Z"/>
<glyph unicode="™" glyphtame="trademark" horizAdvX="472" d="M24 652V700H185V652H130V429H80V652H24ZM387 700H442V429H393V486Q393 507 397 615L394 616Q389 602 371 551L352 497H305L286 551Q268 602 263 616L260 615Q264 507 264 486V429H215V700H270L296 636Q319 584 328 544Q334 565 360 636L387 700Z"/>
<glyph unicode="∂" glyphtame="partialdiff" horizAdvX="458" d="M176 736Q280 707 345 623T412 402V189Q412 93 363 41T228 -11Q143 -11 95 41T46 189V309Q46 404 92 456T217 509Q272 509 309 479Q281 611 142 657L176 736ZM314 189V309Q314 415 229 415T144 309V189Q144 83 228 83Q314 83 314 189Z"/>
<glyph unicode="∅" glyphtame="emptyset" horizAdvX="801" d="M754 732L657 609Q752 511 752 350Q752 187 655 89T399 -10Q290 -10 208 38L153 -32H48L143 89Q46 186 46 350Q46 513 143 611T399 710Q510 710 593 660L650 732H754ZM128 350Q128 230 195 155L542 595Q479 631 399 631Q277 631 203 554T128 350ZM669 350Q669 467 605 543L259 103Q321 69 399 69Q520 69 594 146T669 350Z"/>
<glyph unicode="∏" glyphtame="product" horizAdvX="512" d="M70 -99V700H442V-100H343V604H169V-99H70Z"/>
<glyph unicode="∑" glyphtame="summation" horizAdvX="375" d="M35 -2V80L186 350L35 620V702H340V612H142L271 379V321L142 88H340V-2H35Z"/>
<glyph unicode="−" glyphtame="minus" horizAdvX="461" d="M43 265V355H417V265H43Z"/>
<glyph unicode="√" glyphtame="radical" horizAdvX="487" d="M474 792L322 0H183L98 413H13V500H176L252 90L379 792H474Z"/>
<glyph unicode="∞" glyphtame="infinity" horizAdvX="627" d="M439 409Q499 409 540 365T581 251Q581 182 541 139T440 96Q361 96 314 171Q265 96 187 96Q127 96 87 139T46 251Q46 321 87 365T189 409Q263 409 314 342Q363 409 439 409ZM193 181Q218 181 233 199T265 255Q236 324 193 324Q166 324 150 306T134 252Q134 220 150 201T193 181ZM434 181Q460 181 476 200T493 252Q493 287 477 305T434 324Q409 324 393 307T363 255Q378 218 393 200T434 181Z"/>
<glyph unicode="∫" glyphtame="integral'" horizAdvX="323" d="M24 -190V-97Q113 -96 113 -13V525Q113 613 160 661T300 710V618Q210 617 210 534V-4Q210 -92 163 -140T24 -190Z"/>
<glyph unicode="≈" glyphtame="approxequal" horizAdvX="470" d="M381 436L429 370Q389 318 329 318Q294 318 234 338T149 359Q113 359 89 328L41 395Q82 446 141 446Q176 446 236 426T321 405Q355 405 381 436ZM381 269L429 202Q389 150 329 150Q294 150 234 171T149 192Q113 192 89 160L41 228Q82 279 141 279Q176 279 236 258T321 237Q355 237 381 269Z"/>
<glyph unicode="≠" glyphtame="notequal" horizAdvX="470" d="M422 365H296L259 286H422V196H217L184 126H99L132 196H48V286H174L211 365H48V455H253L286 526H371L338 455H422V365Z"/>
<glyph unicode="≤" glyphtame="lessequal" horizAdvX="431" d="M388 552V461L142 367L388 272V181L44 318V415L388 552ZM44 63V148H388V63H44Z"/>
<glyph unicode="≥" glyphtame="greaterequal" horizAdvX="431" d="M43 552L387 415V318L43 181V272L289 367L43 461V552ZM43 63V148H387V63H43Z"/>
<glyph unicode="◊" glyphtame="lozenge" horizAdvX="467" d="M181 -1L46 332V369L181 700H286L421 369V332L286 -1H181ZM233 96L332 350L233 604L135 350L233 96Z"/>
</font>
</defs><g transform="matrix(18.6046511627907, 0, 0, 18.6046511627907, 400, 400)"><g><g transform="matrix(0.2905994458336149, 0, 0, 0.2905994458336149, 0, 45.927248766641895)"><g><path d="M251,35v-8.7h0V23.25l-9.65,5.46h0L210.13,46.36V12.5h0V.17l-14.07,8L125.81,47.9l-75-42.44h0L41.1,0V3.08h0v8.69h0V46.49L9.68,28.71h0L0,23.25v3.08H0V35H0v83.84H0v9.19L84.74,176l40.76-23.07L166.26,176,251,128.06v-9.19h0V35ZM50.78,17.74,63.9,25.17l61.94,35L200.45,18V89.85h0V98.2l-74.64,42.25L50.78,98Zm33.93,146-75-42.44v-8.14h0V41L22.8,48.44v0L41.09,58.77V95.61h0v9.2L82,127.93v.37l32.64,18.48Zm156.61-50.58h0v8.14l-75,42.44-29.9-16.93L169,128.3,210.13,105V95.61h0v-37L228.2,48.42v0L241.32,41Z" fill="white" id="rnd1"/></g></g><g transform="matrix(0.9066702710008786, 0, 0, 0.9066702710008786, 84.05298371291478, 70.1399945934987)"><text xmlSpace="preserve" fontFamily="Akrobat-Bold, &quot;Akrobat Bold&quot;, 'Akrobat Bold', Akrobat" fontSize="24" x="0" y="0" letterSpacing="0px" fill="white" fontWeight="bold" fontStyle="normal" style={{ fontFamily:'Akrobat-Bold', fontWeight:'bold'}}>WARMDOM</text></g><g transform="matrix(0.34871933500033786, 0, 0, 0.34871933500033786, 83.63452051091437, 83.5656889910117)"><text xmlSpace="preserve" fontFamily="Akrobat-Bold" fontSize="24" x="0" y="0" letterSpacing="3.6px" fill="white" fontWeight="bold" fontStyle="normal" style={{fontFamily:'Akrobat-Bold', fontWeight:'bold'}}>БЕЗУСЛОВНАЯ</text></g><g transform="matrix(0.34871933500033786, 0, 0, 0.34871933500033786, 83.63452051091437, 93.53906197202136)"><text xmlSpace="preserve" fontFamily="Akrobat-Bold" fontSize="24" x="0" y="0" letterSpacing="3.6px" fill="white" fontWeight="bold" fontStyle="normal" style={{fontFamily:'Akrobat-Bold', fontWeight:'bold'}}>НАДЁЖНОСТЬ</text>
</g></g></g>
</svg>
            </div>
              </Link>
            <div className="number">
              <a href={'tel:+380665714156'}><i className="fas fa-phone-alt"></i></a>
            <span><a className="call" href={'tel:+380665714156'}>+38 (066) 571-41-56</a></span>
            <div className="social-icon"> 
              <div className="icon-insta">
                <a href={'https://www.instagram.com/warm_dom_kh/'}><i className="fab fa-instagram"></i></a>
              </div>
              <div className="icon-fb"> 
                <a href={'https://www.facebook.com/profile.php?id=100034690310497'}><i className="fab fa-facebook-f"></i></a>
              </div>
              <div className="icon-mail"> 
                <a href={'mailto:warmdom600@gmail.com'}><i className="far fa-envelope"></i></a>
              </div>
            </div>
            </div>
          </div>
          <Menu/>
        </div>
        
      </header>
    )
}