import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";
import { NavBar } from "../NavBar/NavBar";
import './Product.scss';
import { Modal } from "../../Header/Modal/Modal";

export const Product = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      fetch("/db/db.json")
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }, 2000);
  }, []);


  if(isLoading) {
     return(
         <div style={{textAlign: 'center', }}>
             <h2>Loading...</h2>
            <img src="/img/loadingLogo.png" alt="logo" width={100} height={100}/>
         </div>
     )
  }

  

  return (
    <>
      <Header />
      <div className="containerProd">
        <div className="productPage">
          <div className="productNavBar">
            <NavBar />  

          </div>
          <div className="productBox">
            {data.map((item) =>
              item.id === id ? (
                <div className="productInfo" key={id + item.name}>
                      <h3>{item.name}</h3>
                  <div className="productInfoMain">
                    <div className="infoMainLeft">
                      <img src={item.image} alt="product" width="200" ></img>
                    </div>
                    <div className="infoMainRight">
                        <table className="tablePrice" align="center">
                          <tbody>
                      {item.price.map((itemPrice, index)=>(
                          <tr key={itemPrice.value}>
                            <td >{itemPrice.key}</td>
                            <td style={{fontWeight:  '700'}}>{itemPrice.value}</td>
                          </tr>
                      ))}
                      </tbody>
                        </table>
                        <button 
                        onClick={() => setModalActive(true)} 
                        className="btnBuyProduct">
                          <i className="fas fa-shopping-cart"></i> Купити
                          </button>
                        
                        <Modal
        active={modalActive}
        setActive={setModalActive}
        />
                        
                    </div>
                  </div>
                  <div className="productInfoAdd">
                    <h4>Опис</h4>
                    <p>{item.description}</p>
                    <h4>Технічна інформація</h4>
                    <table className="specification">
                      <tbody>
                      {
                        item.characteristic.map((char, index)=>(
                         <tr key={char.value}>
                          <th >{char.key}</th>
                          <td >{char.value}</td>
                        </tr>

))
}
                    </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
};
